// forms
.disabled{
	background: #EBE9E4 !important;
}

// form list
.form{
	&-list{
		margin: 0 auto;
		border-radius: 0 !important;
		&.bdn{
			border: none !important;
		}
		&.row{
			li{
				& > *{
					width: 100% !important;
				}
				.tit{
					&.btn-set{
						display: flex;
						align-items: center;
						justify-content: space-between;
						padding-right: 10px;
						line-height: 2;
					}
				}
				.con{
					&.scroll{
						overflow: hidden;
						overflow-y: auto;
						align-items: flex-start;
						max-height: calc(100vh - 350px);
						padding: 0 10px;
						margin: 20px 0 0;
					}
				}
			}
		}
		&.add{
			width: 100%;
			margin-top: 10px;
			&:first-child{
				margin-top: 0;
			}
			.tit{
				display: flex;
				justify-content: space-between;
				width: 100%;
				margin-bottom: 10px;
			}
			.con{
				width: 100%;
				.form-group{
					& > * + *:not(.v-btn){
						margin-left: 10px !important;
					}
					.inp{
						flex: inherit;
						width: calc(100% - 184px);
						.v-input__slot{
							padding: 0 15px;
						}
						@include font(12px);
						&:first-child{
							width: 140px;
						}
					}
				}
			}
		}
		&>li{
			display: flex;
			flex-wrap: wrap;
			align-items: baseline;
			width: 100%;
			padding: 10px 0;
			@include font(14px, $fc-k);
			&:first-child{
				border-top: none;
			}
			.tit{
				width: 160px;
				@include font(14px, $fc-k);
				font-weight: $fw-bold;
			}
			.con{
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				width: calc(100% - 160px);
				.icon{
					&.os{
						display: inline-flex;
						width: auto;
						margin-left: 10px;
					}
				}
				.btn-groups{
					margin-top: 20px !important;
				}
				.inp{
					.v-input__slot{
						height: 40px !important;
					}
				}
				.col-4{
					flex: auto !important;
					max-width: calc(33.33333% - 15px) !important;
				}
			}
		}
		// center
		&.align-center{
			li{
				align-items: center;
				.v-label{
					font-weight: $fw-regular;
				}
			}
		}
	}
	&-sub-list{
		width: 100%;
		&.row{
			display: flex !important;
			flex-wrap: nowrap;
			width: 100%;
			margin: 0 !important;
			& > * + *{
				margin-left: 15px;
			}
			li{
				flex: auto;
				margin-top: 0;
				margin-bottom: 0;
				.tit{
					width: 100% !important;
					margin-bottom: 5px;
				}
				.con{
					width: 100% !important;
				}
			}
		}
		li{
			display: flex;
			flex-wrap: wrap;
			margin: 5px 0;
			.tit{
				margin-top: 10px;
				width: 200px !important;
				@include font(12px);
				font-weight: $fw-regular;
			}
			.con{
				width: calc(100% - 200px) !important;
				.v-label{
					font-weight: $fw-regular;
				}
			}
			&.type2{
				width:49%;
				.tit {width:160px !important;}
				.con {width: calc(100% - 160px) !important;}
			}
		}
		&.type2{
			border-top: 1px solid #ccc;
			padding: 10px 0;
			&:first-child{
				border-top: 0;
				padding-top: 0;
			}
		}
	}
	&-group{
		position: relative;
		display: flex;
		align-items: center;
		width: 100%;
		margin-top: 10px;
		&:first-child{
			margin-top: 0;
		}
		& > * + *:not(.v-btn){
			margin-left: 15px !important;
		}
		.form-add{
			&-list{
				width: calc(100% - 35px);
				& > *{
					width: 100% !important;
					margin-top: 10px !important;
					&:first-child{
						margin-top: 0 !important;
					}
				}
			}
			&-btn{
				margin-top: 8px;
			}
		}
		&.full-width{
			& > *{
				width: 100% !important;
				margin: 10px 0 0 !important;
				&:first-child{
					margin-top: 0 !important;
				}
			}
		}
		.v-input--checkbox + .v-input{
			width: 100px;
		}
		.v-btn{
			margin-left: 10px;
		}
		.link-title{
			position: absolute;
			top: 50%;
			left: -110px;
			transform: translateY(-50%);
		}
	}
	// dvs
	&-dvs{
		margin-top: 30px;
		&:first-child{
			margin-top: 0;
		}
		.form-tit{
			width: 200px;
			margin-bottom: 15px;
			@include font(14px);
			font-weight: $fw-bold;
		}
		.form-inner{
			width: 100%;
			li{
				display: flex;
				align-items: center;
				margin-top: 10px;
				&:first-child{
					margin-top: 0;
				}
				.stit{
					width: 200px;
					@include font(13px);
				}
				.inp{
					width: 100%;
				}
			}
		}
	}

	@media all and (max-width: 1280px){
		&-list{
			li{
				@include font(11px);
			}
			::placeholder{
				@include font(11px);
			}
		}
	}
}

// guide text
.guide-text{
	width: 100%;
	margin-top: 10px;
	@include font(14px, $fc-k);
}

// guide item
.guide-item{
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	@include font(14px, $fc-k);
	.txt{
		margin: 2px 10px 2px 0;
		.stit{
			margin-left: 3px;
			@include font(13px, $fc-g2);
		}
	}
}

// info text
.info-text{
	display: flex;
	align-items: center;
	width: 100%;
	margin-top: 5px;
	@include font(12px, $primary);
	&::before{
		content: '';
		width: 16px;
		height: 16px;
		margin-right: 5px;
		background: url('~@/assets/images/icon/ico_info.svg') no-repeat 0 0;
		background-size: 100%;
	}
}

// error-text
.error--text{
	width: 100%;
	padding: 5px 5px 0 0 !important;
	@include font(12px, $primary);
}

// srch
.srch-set{
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	width: 100%;
	padding: 10px 0 10px;
	& > *:not(.v-btn){
		margin-right: 10px !important;
	}
	.inp-srch{
		min-width: 300px;
		width: auto;
	}
	.slct{
		width: 170px;
		&.v-input{
			max-width: max-content !important;
			.v-input__control{
				width: 200px;
			}
		}
		.v-select{
			&.v-input--is-dirty{
				input{
					display: none !important;
				}
			}
			&__selection{
				max-width: none !important;
			}
		}
		&.v-input--is-dirty input{
			display: none;
		}
		&.fit{
			width: auto;
			min-width: 100px;
			max-width: 350px !important;
			.v-input__control{
				width: auto;
				.v-input__slot{
					.v-select__selections {
						.item-text{
							max-width: 270px;
							margin-right: 5px;
							span{
								display: block;
								max-width: 100%;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
							}
						}
						.text-caption{
							margin: 0 5px;
						}
					}
				}
			}
		}
	}
	.v-btn:not(.ml-0) {
		margin-left: 5px;
	}
	.group-right {
		margin-left: auto;
	}
	// group
	&.group{
		align-items: flex-end;
		margin-top: -10px;
		.col{
			margin-top: 10px;
		}
	}
	.col{
		padding: 0;
		.tit{
			margin: 0 0 7px 7px;
			@include font(12px, $fc-k);
			font-weight: $fw-bold;
		}
		&.btns{
			margin: 10px 0 0 !important;
		}
	}
	&.between{
		justify-content: space-between;
	}
	.rgt-set{
		display: flex;
		align-items: center;
		.btn-circle{
			margin-left: 10px !important;
		}
	}
}

// 셀렉트 chip
.slct-chip{
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	width: 100%;
	min-height: 40px;
	margin-top: 5px;
	padding: 10px;
	background: #fff;
	border: 1px solid $input;
	border-radius: 2px;
	@include font(12px, $fc-g1);
	.v-chip{
		margin: 5px;
	}
	&.disabled{
		padding: 10px 20px;
	}
}

// checkbox
.v-input--checkbox{
	.v-label{
		margin-left: 5px;
		@include font(14px !important, $fc-k !important);
	}
	.mdi-checkbox-blank-outline::before{
		color: $fc-g4;
	}
	&.chk{
		.v-input__control{
			.v-input__slot{
				align-items: flex-start;
				.v-input--selection-controls__input .v-icon{
					margin-top: 6px;
				}
			}
		}
	}
	@media all and (max-width: 1280px){
		.v-label{
			@include font(13px !important);
		}
	}
}
.checkbox-group{
	width: 100%;
	padding: 10px;
	border: 1px solid #DEDEDE;
	margin-top: 5px;
	&.bdn{
		padding: 0;
		border: 0;
	}
}

.chk-list-set{
	.v-input__slot{
		padding: 0 !important;
	}
}
.chk-all{
  &.type2{
    margin-top: 10px;
  }
  &.app{
	padding: 15px 24px 10px 24px;
  }
}
.chk-list{
	&.row{
		margin: 0 !important;
		& > * + *{
			margin-left: 50px;
		}
	}
	&.type2{
		margin-top: 0px;
		&>li{
			margin-top: 0px;
			&:first-child{
				margin-top: 8px;
			}
		}
	}

	@media all and (max-width: 1280px){
		&.row{
			display: grid !important;
			grid-template-columns: repeat(3, 1fr);
			gap: 5px;
			& > * + *{
				margin: 0 !important;
			}
			.v-input,
			.v-input__slot{
				min-height: 30px !important;
			}
		}
	}
}

.chk-labels{
	.tit{
		@include font(14px);
	}
	.stit{
		@include font(12px, $fc-g4);
		font-weight: $fw-regular;
	}
}

// radiobox
.v-radio{
	.v-label{
		margin-left: 5px;
		@include font(14px !important, $fc-k !important);
	}
	.v-icon{
		color: $fc-g4 !important;
	}
}
.lighten-disabled {
	.v-radio--is-disabled {
		.v-icon,
		label {
			color: rgba(0, 0, 0, 0.2) !important;
		}
	}
}
.rdo-group{
	padding: 25px 0;
	border-bottom: 1px solid #DEDEDE;
	.v-radio{
		margin: 8px 16px 0 0;
	}
}

// input
.v-input{
	&__slot{
		margin: 0 !important;
		box-shadow: none !important;
		background: #fff !important;
	}
	&--is-disabled:not(.v-input--is-readonly):not(.v-input--checkbox){
		.v-input__slot{
			background: #f6f5f4 !important;
		}
	}
	&--selection-controls{
		margin: 0 !important;
		padding: 0 !important;
		&__input{
			height: 18px !important;
			margin: 0 !important;
		}
	}
	&--switch{
		&__track{
			left: 0 !important;
			border: 1px solid rgba(0, 0, 0, 0.2);
		}
		&__thumb{
			top: calc(50% - 4.5px) !important;
			margin-left: 4px;
			background: #fff !important;
		}
	}
	.v-text-field__details{
		margin-bottom: 0 !important;
		padding-left: 0 !important;
	}
}

.inp{
	&-srch{
		width: 100%;
		&__slct{
			width: 100%;
			.v-input{
				margin: 0 !important;
				&__slot{
					padding: 0 10px !important;
					background: $secondary;
					box-shadow: none;
					font-weight: $fw-regular;
				}
				&__prepend-inner{
					width: 24px;
					padding-right: 7px !important;
				}
				&__icon{
					width: 14px;
				}
				// 삭제버튼
				&__icon--clear{
					.v-icon{
						@include font(18px);
					}
				}
				&--is-focused{
					.v-input__slot{
						background: #fff !important;
					}
				}
			}
		}
	}
	&-set{
		display: flex;
		flex: auto;
		align-items: center;
		.txt{
			display: inline-flex;
			align-items: center;
			margin-left: 5px;
		}
	}
	// 파일첨부
	&-file{
		width: 100%;
		border-radius: 2px;
		.v-input{
			height: 100%;
			flex-direction: row-reverse;
			&__prepend-outer{
				height: 100%;
				margin: 0 !important;
				.v-input__icon{
					width: auto;
					height: 100%;
				}
				.mdi{
					&-paperclip{
						&::before{
							content: 'Browse';
							display: flex;
							align-items: center;
							justify-content: center;
							width: 80px;
							height: 40px;
							background: #e9ecef;
							border: 1px solid #dedede;
							border-left: none;
							border-radius: 0 2px 2px 0;
							@include font(12px);
							font-weight: $fw-bold;
						}
					}
				}
			}
			&__control{
				height: 100%;
				fieldset{
					border: 0 !important;
				}
				.v-input__slot{
					height: 100%;
					min-height: 40px !important;
					border: 1px solid #dedede;
					.v-text-field{
						&__slot{
							min-height: 100% !important;
							label{
								@include font(13px !important);
							}
							.v-file-input__text{
								@include font(13px !important);
							}
						}
					}
				}
				.v-text-field__details{
					margin: 5px 0 0 !important;
					padding: 0 !important;
					.v-messages{
						@include font(11px !important, $fc-g2 !important);
					}
				}
			}
		}
		.v-label{
			@include font(13px !important, $fc-g2 !important);
		}
		.v-image{
			margin-top: 10px;
		}
	}
	&.v-input--is-disabled{
		fieldset{
			background: #F6F6F6 !important;
		}
		&.v-input--is-focused,
		&.v-input--is-label-active{
			.v-label{
				background: transparent;
			}
		}
	}
	&.v-input--is-focused,
	&.v-input--is-label-active{
		.v-label{
			top: 16px !important;
			padding: 0 10px;
			background: #fff;
			@include font(14px !important);
		}
	}
	.v-label{
		@include font(13px);
		font-weight: $fw-regular !important;
		top: 6px !important;
	}

	@media all and (max-width: 1280px){
		&-file{
			.v-input{
				&__control{
					.v-input__slot{
						.v-text-field{
							&__slot{
								label{
									@include font(11px !important);
								}
								.v-file-input__text{
									@include font(11px !important);
								}
							}
						}
					}
				}
			}
			.v-label{
				@include font(11px !important);
			}
		}
	}
}


// select
.slct{
	&.fixed-width{
		width: 260px;
	}
	position: relative;
	.v-input{
		&__control{
			min-height: auto !important;
			.v-input__slot{
				min-width: 125px;
				padding: 0 20px !important;
				box-shadow: none !important;
			}
			.v-input__prepend-inner{
				width: auto;
				min-width: fit-content;
				margin: 2px 0 0!important;
				box-sizing: border-box;
				padding-right: 5px !important;
				.v-input__icon--prepend-inner{
					min-width: auto;
					width: auto;
				}
			}
			fieldset{
				border-color: $input !important;
			}
		}
		&__icon{
			.v-icon{
				&::before{
					content: '';
					width: 12px;
					height: 6px;
					background: url('~@/assets/images/slct_arr.png') no-repeat 0 0;
					background-size: 100%;
				}
				&.mdi{
					&::before{
						@include font(12px !important, $fc-g1 !important);
					}
					&-app{
						&::before{
							flex-shrink: 0;
							width: 100%;
							height: 100%;
							background: none;
							content: 'App Authority: ';
						}
					}
					&-ads{
						&::before{
							content: 'app-ads.txt Status: ';
							flex-shrink: 0;
							width: 100%;
							height: 100%;
							background: none;
						}
					}
					&-category{
						&::before{
							content: '카테고리: ';
							flex-shrink: 0;
							width: 100%;
							height: 100%;
							background: none;
						}
					}
					&-company{
						&::before{
							content: 'Company: ';
							flex-shrink: 0;
							width: 100%;
							height: 100%;
							background: none;
						}
					}
					&-country{
						&::before{
							content: 'Country: ';
							flex-shrink: 0;
							width: 100%;
							height: 100%;
							background: none;
						}
					}
					&-med{
						&::before{
							content: 'Mediation: ';
							flex-shrink: 0;
							width: 100%;
							height: 100%;
							background: none;
						}
					}
					&-menu{
						&::before{
							flex-shrink: 0;
							width: 100%;
							height: 100%;
							background: none;
							content: 'Menu Authority: ';
						}
					}
					&-network{
						&::before{
							content: 'Ad Network: ';
							flex-shrink: 0;
							width: 100%;
							height: 100%;
							background: none;
						}
					}
					&-os{
						&::before{
							flex-shrink: 0;
							width: 100%;
							height: 100%;
							background: none;
							content: 'OS: ';
						}
					}
					&-register{
						&::before{
							flex-shrink: 0;
							width: 100%;
							height: 100%;
							background: none;
							content: 'All Register Status: ';
						}
					}
					&-status{
						&::before{
							flex-shrink: 0;
							width: 100%;
							height: 100%;
							background: none;
							content: 'Status: ';
						}
					}
					&-type{
						&::before{
							content: 'Ad Type: ';
							flex-shrink: 0;
							width: 100%;
							height: 100%;
							background: none;
						}
					}
					&-unit{
						&::before{
							content: 'Ad Unit: ';
							flex-shrink: 0;
							width: 100%;
							height: 100%;
							background: none;
						}
					}
					&-account{
						&::before{
							content: '계정 상태: ';
							flex-shrink: 0;
							width: 100%;
							height: 100%;
							background: none;
						}
					}
					&-regStatus{
						&::before{
							content: '등록 상태: ';
							flex-shrink: 0;
							width: 100%;
							height: 100%;
							background: none;
						}
					}
				}
			}
		}
		&--is-disabled{
			background: red !important;
		}
	}
	.v-select{
		&__selections{
			@include font(13px !important, $fc-g1 !important);
		}
	}
	.v-label{
		height: auto !important;
		@include font(13px !important, $fc-g1 !important);
	}
	.btns{
		display: flex;
		justify-content: center;
	}
	// standard
	&.standard{
		.v-select{
			&__selections{
				color: $fc-k !important;
				input::placeholder{
					color: $fc-k;
				}
			}
		}
		.v-input{
			&__slot{
				justify-content: space-between;
				padding: 0 !important;
			}
			&__icon{
				.v-icon{
					&::before{
						width: 8px;
						height: 7px;
						background: url('~@/assets/images/slct_arr2.png') no-repeat 0 0;
					}
				}
			}
		}
	}
	// color gry
	&.c-gry{
		.v-input{
			&__control{
				fieldset{
					border-color: $secondary !important;
				}
			}
		}
	}
	// active
	&.active{
		.v-input{
			&__control{
				fieldset{
					border: 1px solid $primary !important;
				}
			}
			&__icon{
				.v-icon{
					&::before{
						background: url('~@/assets/images/slct_arr_active.png') no-repeat 0 0;
						background-size: 100%;
					}
				}
			}
		}
	}
	//period
	&.period{
		.v-input__control{
			.v-input__slot{
				padding: 2px 4px!important;
			}
		}
		.v-input__icon{
			.v-icon{
				&::before{
					background: none;
				}
			}
		}
		.v-input__prepend-outer{
			margin-top: 10px !important;
		}

	}
	@media all and (max-width: 1280px){
		.v-input{
			&__control{
				.v-input__prepend-inner{
					margin-top: -1px !important;
				}
			}
			&__icon{
				.v-icon{
					&.mdi{
						&::before{
							@include font(11px !important);
						}
					}
				}
			}
		}
		.v-select{
			&__selections{
				@include font(11px !important);
			}
		}
		.v-label{
			@include font(11px !important);
		}
	}
}

.slct-box{
	width: 100%;
}

.v-menu{
	&__content{
		background: #fff !important;
		border-radius: 2px !important;
		.v-list{
			border-top: none !important;
			&::before{
				display: none;
			}
			&-item{
				min-height: auto !important;
				margin: 0 !important;
				padding: 0 10px !important;
				border-bottom: none;
				&__title{
					@include font(12px !important, $fc-g1 !important);
				}
				&::before{
					content: none;
				}
				&:hover{
					background: rgba(255,90,77,.12);
				}
			}
			.srch-box{
				display: flex;
				justify-content: center;
				width: 100%;
				padding: 20px 0;
				.inp-srch{
					&__slct{
						.v-input{
							&__slot{
								width: calc(100% - 20px);
								margin: 0 auto !important;
							}
							&__prepend-inner{
								padding-right: 10px;
							}
						}
					}
				}
			}
		}
	}
}

.v-list{
	padding: 0 !important;
	background: none !important;
	border-top: 2px solid $secondary !important;
	&-item{
		padding: 0 5px !important;
		border-bottom: 1px solid #E3E3E3;
		justify-content: space-between;
		&--active{
			.v-list-item__title{
				font-weight: $fw-bold;
			}
		}
		&--highlighted::before{
			opacity: 0.12 !important;
		}
		&__title{
			margin-right: 5px;
			@include font(14px !important, $fc-k);
		}
		.tit-set{
			width: calc(100% - 80px);
			&__title{
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				width: 100%;
				margin-right: 5px;
				@include font(14px !important, $fc-k);
			}
			.stit{
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				width: 100%;
				margin-top: 5px;
				@include font(12px !important, $fc-g1 !important);
				font-weight: $fw-regular;
			}
		}
		&__action{
			margin: 10px 0 !important;
			.mdi-minus-box::before{
				content: '\F0131';
				color:rgba(0, 0, 0, 0.54) !important;
			}
		}
		.icon{
			flex-shrink: 0;
			max-width: 36px;
			width: 36px;
			height: 36px;
			margin-right: 10px;
			background: #EBE9E4;
			border-radius: 8px;
			&.os{
				flex-shrink: 0;
				width: 24px;
				height: 20px;
				margin-left: auto;
				background: none;
				margin-right: 0;
			}
		}
	}
	.btn-groups{
		padding: 15px 0;
	}

	// white
	.white--text{
		.v-list,
		.v-list-item{
			&--active{
				background: $background !important;
			}
		}
	}
	@media all and (max-width: 1280px){
		&-item{
			&__title{
				@include font(12px !important);
			}
			&__action{
				margin: 8px 0 !important;
			}
			.tit-set{
				.stit{
					margin-top: 5px;
					@include font(10px !important);
				}
			}
		}
	}
}

.select-comp-box{
	padding: 20px 25px;
	margin-top: 20px;
	border: 1px solid #E3E3E3;
	border-radius: 2px;
}

// switch
.switch-set{
	width: 60%;
	margin: 0 auto;
	.tit{
		margin-bottom: 10px;
		@include font(20px, $fc-k);
		font-weight: $fw-bold;
	}
}

.empty-inp{
	width: 100%;
	height: 40px;
	margin-top: 5px;
	border: 1px solid #DEDEDE;
	background: #F6F5F4;
}

// date set
.date-set{
	display: flex;
	align-items: center;
	width: 100%;
	margin-bottom: 5px;
	.reportrange-text{
		min-width: 220px;
	}
	.date-txt{
		margin-left: 15px;
		@include font(14px, $fc-g2);

	}
	.mdi-calendar{
		&::before{
			content: '' !important;
			width: 14px !important;
			height: 16px !important;
			margin-bottom: 8px;
			margin-left: 15px;
			background: url('~@/assets/images/icon/ico_calendar.png') no-repeat 0 0 !important;
			background-size: 100% !important;

		}
	}
	.period{
		max-width: 220px;
		border: 1px solid $input;
		&.en{
			max-width: 300px;
		}
	}
	.v-text-field--solo{
		border-radius: 2px;
		.v-input__control{
			margin-left: 5px;
		}
	}
	@media all and (max-width:1280px){
		.date-txt{
			@include font(12px);
		}
	}
}

// report filter
.report-filter-group{
  background-color: #F6F5F4;
	display: flex;
	flex-wrap: wrap;
	& > *{
		margin: 5px 10px 5px 0;
	}
	.date{
		margin: 5px 10px 5px 0;
		.tit{
			margin: 0 0 7px 7px;
			@include font(12px);
			font-weight: $fw-bold;
		}
		.date-txt{
			margin-left: 15px;
			margin-top: 5px;
			@include font(14px, $fc-g2);
		}
	}
  .btn-set{
    margin-left: auto;
    margin-right: 20px;
  }
	&.bottom{
		align-items: flex-end;
	}
	.hide-today {
		.daterangepicker > .calendars > .ranges {
			li[data-range-key='Today'] {
				display: none;
			}
		}
	}
	.monthselect {
		text-align: center;
	}
}
.report-filter{
  background-color: #F6F5F4;
	width: auto;
	min-width: 100px;
	max-width: 350px;
	.tit{
		margin: 0 0 7px 7px;
		@include font(12px);
		font-weight: $fw-bold;
	}
}

.search-select{
	position: relative;
	.selected-item{
    background-color: white;
		z-index: 1;
		display: flex;
		align-items: center;
		width: 100%;
		height: 30px;
		padding: 0 30px 0 15px;
		border: 1px solid #ddd;
		border-radius: 15px;
		cursor: pointer;
		.txt-val{
			display: flex;
			align-items: center;
			margin-right: 5px;
			@include font(14px);
			.item-text{
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				max-width: 270px;
			}
			.text-caption{
				margin-left: 5px;
			}
		}
		.slct-icon{
			@include center(vertical);
			right: 15px;
			width: 12px;
			height: 6px;
			background: url('~@/assets/images/slct_arr.png') no-repeat 0 0;
			background-size: 100%;
			transition: all 0.2s;
			&.open{
				transform: translateY(-50%) rotate(-180deg);
			}
		}
	}
	.dropdown-popover{
		z-index: 3;
		overflow: hidden;
		position: absolute;
		top: 32px;
		left: 0;
		min-width: 150px;
		max-height: 0;
		border: 1px solid $fc-g2;
		background: #fff;
		visibility: hidden;
		transition: all 0.3s linear;
		border-radius: 15px;
		.dropdown-content{
			width: 100%;
			padding: 15px;
		}
		&.visible{
			visibility: visible;
			max-height: 450px;
		}
		.options{
			width: 100%;
			.v-label{
				@include font(13px !important);
				font-weight: $fw-regular;
				white-space: nowrap;
			}
			ul{
				overflow: hidden;
				overflow-x: hidden;
				overflow-y: auto;
				width: 100%;
				margin-top: 10px;
				padding-right: 10px;
				max-height: 330px !important;
				li{
					display: inline-block;
					width: 100%;
					margin-top: 10px;
					&:first-child{
						margin-top: 0;
					}
				}
			}
		}
		.no-data{
			width: 100%;
			@include font(12px);
			text-align: center;
		}
	}
}