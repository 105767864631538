// commponents

// lnb
.lnb {
	position: sticky;
	top: 0;
	width: 80px;
	min-height: 100vh;
	padding: 20px 0;
	background: #222222;
	.logo {
		padding: 0 10px 22px;
	}
	.previousLink {
		position: fixed;
		top: calc(100% - 188px) !important;
		background-color: #444444;
		color: white;
		height: 80px;
		width: 64px;
		margin: 0px 8px 16px 8px;
		.txt {
			text-align: left;
			vertical-align: middle;
			font-size: 9px;
			line-height: 12px;
			font-weight: 400;
			padding: 8px 0 12px 8px;
		}
		.icon {
			height: 16px;
			margin-left: 42px;
			background-size: 16px 16px;
			background: url("~@/assets/images/icon/icon_previous_link.png") no-repeat 0 0;
		}
	}
	.profile {
		position: absolute;
		top: calc(100% - 104px);
		width: calc(100% - 20px);
		margin: 0 10px;
		padding: 10px 0;
		.mypage {
			height: 24px;
			background: url("~@/assets/images/lnb_mypage_active.png") no-repeat 50% 0;
			background-size: 24px 100%;
		}
		.user-id {
			.v-btn {
				width: 60px !important;
				background: #222222 !important;
				.v-btn__content {
					position: absolute !important;
					display: block;
					max-width: 100%;
					display: flex;
					@include font(12px, #fff);
					font-weight: 600;
					.user-name {
						@include ellipsis;
					}
				}
				&::before {
					opacity: 0 !important;
				}
			}
		}
	}
	.lnb-list {
		li {
			& > a {
				display: flex;
				flex-wrap: wrap;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				width: 80px;
				height: 80px;
				@include font(9px, #666666);
				font-weight: $fw-bold;
				text-align: center;
				&::before {
					content: "";
					display: block;
					width: 100%;
					height: 24px;
					margin-bottom: 10px;
				}
			}
			&.ico-home {
				& > a {
					&::before {
						background: url("~@/assets/images/lnb_home.png") no-repeat 50% 0;
						background-size: 24px 100%;
					}
				}
			}
			&.ico-company {
				& > a {
					&::before {
						background: url("~@/assets/images/lnb_company.png") no-repeat 50% 0;
						background-size: 24px 100%;
					}
				}
			}
			&.ico-apps {
				& > a {
					&::before {
						background: url("~@/assets/images/lnb_apps.png") no-repeat 50% 0;
						background-size: 24px 100%;
					}
				}
			}
			&.ico-report {
				& > a {
					&::before {
						background: url("~@/assets/images/lnb_report.png") no-repeat 50% 0;
						background-size: 24px 100%;
					}
				}
			}
			&.ico-mediation {
				& > a {
					&::before {
						background: url("~@/assets/images/lnb_mediation.png") no-repeat 50% 0;
						background-size: 24px 100%;
					}
				}
			}
			&.ico-finance {
				& > a {
					&::before {
						background: url("~@/assets/images/lnb_finance.png") no-repeat 50% 0;
						background-size: 24px 100%;
					}
				}
			}
			&.ico-setting {
				& > a {
					&::before {
						background: url("~@/assets/images/lnb_setting.png") no-repeat 50% 0;
						background-size: 24px 100%;
					}
				}
			}
			&.ico-campaign {
				& > a {
					&::before {
						background: url("~@/assets/images/lnb_campaign.svg") no-repeat 50% 0;
						background-size: 24px 100%;
					}
				}
			}
			&:hover {
				& > a.router-link-active {
					// background: #EFF0F4;
					color: #ffffff;
				}
				&.ico-home {
					& > a.router-link-active {
						&::before {
							background: url("~@/assets/images/lnb_home_active.png") no-repeat 50% 0;
							background-size: 24px 100%;
						}
					}
				}
				&.ico-company {
					& > a.router-link-active {
						&::before {
							background: url("~@/assets/images/lnb_company_active.png") no-repeat 50% 0;
							background-size: 24px 100%;
						}
					}
				}
				&.ico-apps {
					& > a.router-link-active {
						&::before {
							background: url("~@/assets/images/lnb_apps_active.png") no-repeat 50% 0;
							background-size: 24px 100%;
						}
					}
				}
				&.ico-report {
					& > a.router-link-active {
						&::before {
							background: url("~@/assets/images/lnb_report_active.png") no-repeat 50% 0;
							background-size: 24px 100%;
						}
					}
				}
				&.ico-mediation {
					& > a.router-link-active {
						&::before {
							background: url("~@/assets/images/lnb_mediation_active.png") no-repeat 50% 0;
							background-size: 24px 100%;
						}
					}
				}
				&.ico-finance {
					& > a.router-link-active {
						&::before {
							background: url("~@/assets/images/lnb_finance_active.png") no-repeat 50% 0;
							background-size: 24px 100%;
						}
					}
				}
				&.ico-setting {
					& > a.router-link-active {
						&::before {
							background: url("~@/assets/images/lnb_setting_active.png") no-repeat 50% 0;
							background-size: 24px 100%;
						}
					}
				}
				&.ico-campaign {
					& > a.router-link-active {
						&::before {
							background: url("~@/assets/images/lnb_campaign_active.svg") no-repeat 50% 0;
							background-size: 24px 100%;
						}
					}
				}
			}
			& > a.router-link-active {
				// background: #EFF0F4;
				color: #ffffff;
			}

			&.ico-home {
				& > a.router-link-active {
					&::before {
						background: url("~@/assets/images/lnb_home_active.png") no-repeat 50% 0;
						background-size: 24px 100%;
					}
				}
			}

			&.ico-company {
				& > a.router-link-active {
					&::before {
						background: url("~@/assets/images/lnb_company_active.png") no-repeat 50% 0;
						background-size: 24px 100%;
					}
				}
			}

			&.ico-apps {
				& > a.router-link-active {
					&::before {
						background: url("~@/assets/images/lnb_apps_active.png") no-repeat 50% 0;
						background-size: 24px 100%;
					}
				}
			}

			&.ico-report {
				& > a.router-link-active {
					&::before {
						background: url("~@/assets/images/lnb_report_active.png") no-repeat 50% 0;
						background-size: 24px 100%;
					}
				}
			}

			&.ico-mediation {
				& > a.router-link-active {
					&::before {
						background: url("~@/assets/images/lnb_mediation_active.png") no-repeat 50% 0;
						background-size: 24px 100%;
					}
				}
			}

			&.ico-finance {
				& > a.router-link-active {
					&::before {
						background: url("~@/assets/images/lnb_finance_active.png") no-repeat 50% 0;
						background-size: 24px 100%;
					}
				}
			}

			&.ico-setting {
				& > a.router-link-active {
					&::before {
						background: url("~@/assets/images/lnb_setting_active.png") no-repeat 50% 0;
						background-size: 24px 100%;
					}
				}
			}

			&.ico-campaign {
				& > a.router-link-active {
					&::before {
						background: url("~@/assets/images/lnb_campaign_active.svg") no-repeat 50% 0;
						background-size: 24px 100%;
					}
				}
			}
		}
		&.user-lnb {
			li {
				&.ico-dashboard {
					& > a {
						&::before {
							background: url("~@/assets/images/lnb_dashboard.png") no-repeat 50% 0;
							background-size: 24px 100%;
						}
					}
				}
				&.ico-report {
					& > a {
						&::before {
							background: url("~@/assets/images/lnb_report.png") no-repeat 50% 0;
							background-size: 24px 100%;
						}
					}
				}
				&.ico-apps {
					& > a {
						&::before {
							background: url("~@/assets/images/lnb_apps.png") no-repeat 50% 0;
							background-size: 24px 100%;
						}
					}
				}
				&.ico-finance {
					& > a {
						&::before {
							background: url("~@/assets/images/lnb_finance.png") no-repeat 50% 0;
							background-size: 24px 100%;
						}
					}
				}
				&.ico-account {
					& > a {
						&::before {
							background: url("~@/assets/images/lnb_account.png") no-repeat 50% 0;
							background-size: 24px 100%;
						}
					}
				}
				&.ico-campaign {
					& > a {
						&::before {
							background: url("~@/assets/images/lnb_campaign.svg") no-repeat 50% 0;
							background-size: 24px 100%;
						}
					}
				}
				&:hover {
					& > a.router-link-active {
						// background: #EFF0F4;
						color: #ffffff;
					}
					&.ico-dashboard {
						& > a.router-link-active {
							&::before {
								background: url("~@/assets/images/lnb_dashboard_active.png") no-repeat 50% 0;
								background-size: 24px 100%;
							}
						}
					}
					&.ico-report {
						& > a.router-link-active {
							&::before {
								background: url("~@/assets/images/lnb_report_active.png") no-repeat 50% 0;
								background-size: 24px 100%;
							}
						}
					}
					&.ico-apps {
						& > a.router-link-active {
							&::before {
								background: url("~@/assets/images/lnb_apps_active.png") no-repeat 50% 0;
								background-size: 24px 100%;
							}
						}
					}
					&.ico-finance {
						& > a.router-link-active {
							&::before {
								background: url("~@/assets/images/lnb_finance_active.png") no-repeat 50% 0;
								background-size: 24px 100%;
							}
						}
					}
					&.ico-account {
						& > a.router-link-active {
							&::before {
								background: url("~@/assets/images/lnb_account_active.png") no-repeat 50% 0;
								background-size: 24px 100%;
							}
						}
					}
					&.ico-campaign {
						& > a.router-link-active {
							&::before {
								background: url("~@/assets/images/lnb_campaign_active.svg") no-repeat 50% 0;
								background-size: 24px 100%;
							}
						}
					}
				}
				& > a.router-link-active {
					// background: #EFF0F4;
					color: #ffffff;
				}
				&.ico-dashboard {
					& > a.router-link-active {
						&::before {
							background: url("~@/assets/images/lnb_dashboard_active.png") no-repeat 50% 0;
							background-size: 24px 100%;
						}
					}
				}

				&.ico-report {
					& > a.router-link-active {
						&::before {
							background: url("~@/assets/images/lnb_report_active.png") no-repeat 50% 0;
							background-size: 24px 100%;
						}
					}
				}

				&.ico-apps {
					& > a.router-link-active {
						&::before {
							background: url("~@/assets/images/lnb_apps_active.png") no-repeat 50% 0;
							background-size: 24px 100%;
						}
					}
				}

				&.ico-finance {
					& > a.router-link-active {
						&::before {
							background: url("~@/assets/images/lnb_finance_active.png") no-repeat 50% 0;
							background-size: 24px 100%;
						}
					}
				}

				&.ico-account {
					& > a.router-link-active {
						&::before {
							background: url("~@/assets/images/lnb_account_active.png") no-repeat 50% 0;
							background-size: 24px 100%;
						}
					}
				}

				&.ico-campaign {
					& > a.router-link-active {
						&::before {
							background: url("~@/assets/images/lnb_campaign_active.svg") no-repeat 50% 0;
							background-size: 24px 100%;
						}
					}
				}
			}
		}
	}
}

// title
.page-info {
	display: flex;
	margin-left: 20px;
	.slct {
		align-items: flex-start;
		max-width: 150px;
		height: 32px;
		margin: -2px 0 0 30px !important;
	}
	.btns {
		margin-left: auto;
		& > * + * {
			margin-left: 5px;
		}
		.v-btn {
			font-weight: $fw-regular !important;
		}
	}
}
.page-title {
	margin-bottom: 16px;
	@include font(24px, $fc-k2);
	font-weight: $fw-bold;
	line-height: 1;
}

.sub-title-1 {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 2.5em;
	margin-bottom: 10px;
	.title-info {
		margin: 0 auto 0 40px;
		@include font(12px, $fc-g1);
		font-weight: $fw-regular;
	}
	.inp-srch {
		width: 70%;
	}
	@include font(18px, $fc-k);
	font-weight: $fw-bold;
	@media all and (max-width: 1280px) {
		@include font(16px);
	}
}

.chart_title {
	font-weight: $fw-bold;
	padding: 0px 0 16px 0;
}

.sub-title-2 {
	margin: 20px 0 5px;
	@include font(14px, $primary);
}

.accodian-title {
	padding: 20px 0 10px;
	@include font(14px, $fc-k);
	font-weight: $fw-bold;
}

.link-title {
	display: flex;
	align-items: center;
	@include font(14px, $fc-k);
	&::before {
		content: "";
		flex-shrink: 0;
		width: 24px;
		height: 24px;
		margin-right: 5px;
		background: url("~@/assets/images/icon/ico_link.png") no-repeat 0 0;
	}
}

.breadcrumb {
	line-height: 1;
	li:nth-child(even) {
		padding: 0 6px !important;
	}
	.mdi {
		&-chevron-right {
			&::before {
				content: ">";
				color: $primary;
			}
		}
	}
}

// list (3depth)
.column-box {
	display: flex;
	height: calc(100vh - 90px);
	min-height: 720px;
	.column-set {
		position: relative;
		height: 100%;
		padding: 0 20px 20px;
		border-right: 1px solid;
		border-color: rgba(51, 51, 51, 0.1);
	}
	&.set-2 {
		.column-set {
			&:nth-child(1) {
				width: 21%;
				padding: 0 20px 20px 0;
			}
			&:nth-child(2) {
				width: 79%;
				border-right: 0;
				padding: 0 0 20px 15px;
			}
		}
	}
	&.set-3 {
		.column-set {
			&:nth-child(1) {
				width: 20%;
				padding: 0 20px 20px 0;
			}
			&:nth-child(2) {
				width: 18%;
			}
			&:nth-child(3) {
				width: 62%;
				border-right: 0;
				padding-right: 0;
			}
		}
	}
	&.apps {
		height: calc(100vh - 115px);
	}
}

.list-top-section {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	.title-area {
		display: flex;
		align-items: center;
		.tit-set {
			display: flex;
			align-items: center;
		}
		.top-title {
			margin-left: 10px;
			@include font(20px, $fc-k);
			font-weight: $fw-bold;
		}
		.unit-info {
			display: flex;
			align-items: center;
			margin-left: 20px;
			.info-title {
				@include font(16px, $fc-k);
				font-weight: $fw-regular;
			}
		}
	}

	&.set-2 {
		.column-set {
			display: flex;
			align-items: center;
			padding-bottom: 0 !important;
			&:nth-child(1) {
				justify-content: space-between;
				width: 21%;
				padding: 0 20px 0 0;
			}
			&:nth-child(2) {
				justify-content: space-between;
				width: 79%;
				border-right: 0;
				padding-right: 0;
				padding: 0 0 0 15px;
			}
		}
	}
}

// result
.result-area {
	width: 100%;
	height: calc(100% - 50px);
	margin-top: 20px;
	.list-set {
		overflow-x: hidden;
		overflow-y: auto;
		width: 100%;
		height: 100%;
		&.apps {
			.v-list-item {
				min-height: 65px !important;
				.tit-set {
					width: calc(100% - 40px);
					&__title {
						@include font(14px !important, $fc-k);
					}
				}
			}
		}
	}
}

// report
.report {
	width: 100%;
	padding-bottom: 10px;
	.title {
		width: 100%;
		padding: 20px 20px 20px 10px;
		@include font(18px, $fc-k);
		font-weight: $fw-bold;
	}
	.chart-area {
		display: flex;
		width: 100%;
		height: 500px;
		.empty {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: calc(100% - 45px);
			.txt {
				@include font(16px, $fc-g2);
			}
		}
		.dashboard-chart-item {
			width: 400px;
			color: red;
		}
		.chart-box {
			width: 100%;
			padding: 20px;
			border-right: 1px solid $input;
			border-left: 1px solid $input;
			border-bottom: 1px solid $input;
			.chart {
				width: 100%;
				height: calc(100% - 85px);
				margin: 20px 0 40px;
				.report-charts {
					width: 100%;
					height: 100%;
				}
			}
			.empty {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				width: 100%;
				height: calc(100% - 45px);
				.txt {
					@include font(16px, $fc-g2);
				}
			}
		}
		.srch-box {
			position: absolute;
			width: 350px;
			margin-left: calc(100% - 351px);
			border-left: 1px solid $input;
			.v-expansion-panel-header {
				padding: 0px;
			}
			.v-expansion-panel-header__icon {
				padding-right: 20px;
			}
			.v-expansion-panel-content__wrap {
				padding: 0 0 0 0;
			}
			.title {
				font-weight: 600;
				@include font(12px !important);
				padding: 16px 0px 16px 20px;
			}
			&_top {
				width: 100%;
				padding: 0px 0px 0px 0px;
				border: none !important;
				border-bottom: 1px solid $secondary;
				.inp-srch {
					width: 100%;
					.v-input__slot {
						background-color: #f4f4f4 !important;
					}
					input {
						margin: 5px 5px 5px 5px;
					}
				}
				.legend-menu-area {
					display: flex;
					align-items: center;
					width: 100%;
					height: 50px;
					li {
						display: flex;
						&:first-child {
							width: 30%;
						}
						&:last-child {
							width: 45%;
						}
						.title {
							display: flex;
							margin: 0 0 0 20px;
							padding: 0px;
							@include font(12px !important);
							div {
							}
						}
						.input {
							display: flex;
						}
						.input-right {
							display: flex;
							margin-left: auto;
							margin-right: 0;
							width: 50px;
							background-color: red;
						}
						.v-input {
							max-width: fit-content !important;
							&--switch {
								display: inline-flex;
								align-items: center;
								max-width: fit-content !important;
								margin-left: 10px !important;
							}
						}
					}
				}

				.srch-form {
					display: flex;
					align-items: center;
					width: 100%;
					margin-top: 30px;
					li {
						display: flex;
						align-items: center;
						width: 55%;
						margin: 0 !important;
						@include font(14px, $fc-k);
						&:first-child {
							width: 45%;
						}
						.tit {
							width: 35%;
							font-weight: $fw-bold;
						}
						.con {
							display: flex;
							align-items: center;
							width: 65%;
							padding-left: 5px;
							.v-input {
								max-width: fit-content !important;
								height: 32px;
								&--switch {
									display: inline-flex;
									align-items: center;
									max-width: fit-content !important;
									margin-left: 10px !important;
								}
							}
							.v-select {
								width: 100%;
								&__selections {
									@include font(11px !important);
									input {
										@include ellipsis;
									}
								}
								.v-input__slot {
									min-width: auto !important;
								}
							}
						}
					}
				}
			}
			&_list {
				overflow-x: hidden;
				overflow-y: auto;
				width: 100%;
				height: 342px;
				li {
					display: flex;
					align-items: center;
					height: 40px;
					padding: 0 20px;
					border-bottom: 1px solid $input;
					.row {
						width: 100%;
					}
					.v-input {
						label {
							width: 100%;
							height: inherit;
							font-weight: $fw-regular;
						}
					}
					// no search result
					&.empty {
						justify-content: center;
						height: 100%;
						padding-top: 40px;
						border-bottom: none;
						@include font(14px, $fc-k);
					}
				}
			}
		}
	}
	.table-area {
		// border-top: 1px solid $input;
	}
}

// accordion
.accordion {
	overflow-x: hidden;
	overflow-y: auto;
	width: 100%;
	height: 100%;
	border-top: 2px solid $secondary !important;

	// data table
	.v-data {
		&-table__wrapper {
			height: auto;
		}
	}
	.v-expansion-panels {
		padding: 15px 5px 0;
	}
	.v-expansion-panel {
		border: 1px solid #dedede;
		margin-top: -1px;
		&::after {
			border-color: #e3e3e3 !important;
		}
		&-header {
			padding: 0 20px;
			@include font(14px !important);
			.v-chip {
				max-width: fit-content !important;
				margin-left: 10px;
			}
			&__icon {
				.v-icon {
					&::before {
						content: "";
						width: 11px;
						height: 6px;
						background: url("~@/assets/images/btn/btn_down.png") no-repeat 0 0;
						background-size: 100%;
					}
				}
			}
			&--active {
				min-height: 48px !important;
				&::before {
					opacity: 1 !important;
					background: #f6f5f4;
					border-bottom: 1px solid #dedede;
				}
				.tit-set {
					z-index: 2;
					.v-chip--outlined.v-chip {
						background-color: #fff !important;
					}
				}
				.v-expansion-panel-header {
					&__icon {
						.v-icon {
							&::before {
								background: url("~@/assets/images/btn/btn_up.png") no-repeat 0 0;
								background-size: 100%;
							}
						}
					}
				}
			}
		}
		&-content {
			@include font(14px !important);
			&__wrap {
				padding: 0 15px 15px !important;
			}
		}
		&--active > * {
			background: #fff;
		}
	}
	&.mediation {
		.v-expansion-panel-content {
			&__wrap {
				padding: 15px 15px 15px !important;
			}
		}
	}

	@media all and (max-width: 1280px) {
		.v-expansion-panel {
			&-header {
				@include font(12px !important);
			}
			&-content {
				@include font(12px !important);
			}
		}
	}
}

// tab
.v-tabs {
	&-bar {
		position: relative;
		height: 50px !important;
		margin-bottom: 30px;
	}
	&-slider-wrapper {
		height: 3px !important;
	}
	.v-slide-group {
		position: relative;
		&::before {
			content: "";
			z-index: 0;
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 2px;
			background: #ddd;
		}
	}
	.v-tab {
		height: 50px;
		padding: 0 0 5px;
		margin-right: 70px;
		color: #000 !important;
		font-weight: $fw-regular !important;
		text-transform: none !important;
		&:first-child {
			margin-left: 0;
		}
		& > * + * {
			margin-left: 20px;
		}
		&--active {
			color: black !important;
			font-size: 15px !important;
			font-weight: $fw-bold !important;
		}
	}
	&-items {
		.v-card {
			box-shadow: none !important;
		}
	}

	// type2
	&.type2 {
		.v-tabs {
			&-bar {
				height: 50px !important;
			}
			&-slider-wrapper {
				display: none;
			}
			&-items {
				.v-card {
					padding: 0;
					.form-list {
						margin: 0;
					}
				}
			}
		}
		.v-slide-group {
			&::before {
				height: 1px;
			}
		}
		.v-tab {
			width: 160px;
			height: 50px;
			margin-right: 0;
			border: 1px solid #dedede;
			border-radius: 6px 6px 0px 0px;
			&--active {
				background: #fff;
				border-color: $primary;
				border-bottom: none;
			}
		}
		.no-data {
			width: 100%;
			text-align: center;
			min-height: 200px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}

// btn-groups
.btn-groups {
	position: sticky;
	bottom: 0;
	display: flex;
	justify-content: center;
	width: 100%;
	background: #fff;
	* + * {
		margin-left: 10px;
	}
	&.row {
		margin: 0 !important;
	}
}

.btn-area {
	width: 100%;
	text-align: center;
	padding: 10px 0 5px;
	&.add {
		text-align: left;
		border-bottom: 1px solid $secondary;
	}
}

.archive-area {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
	padding: 15px 0 0 0;
	&.auto {
		justify-content: space-between;
		padding: 15px 0 10px 0;
	}
	.v-btn {
		margin-left: 10px;
		&.btn-circle {
			margin-right: 10px;
		}
	}
	.add-set {
		display: flex;
		align-items: center;
	}
}

.flag-id {
	padding-bottom: 10px;
	span {
		margin-left: 30px;
		@include font(13px, $fc-g2);
		&:first-child {
			margin-left: 0;
		}
	}
}

// 아이콘 radio-group
.rdo-icon-group {
	width: 100%;
	.col {
		width: 30%;
		flex-basis: inherit;
		&::after {
			content: "";
			display: block;
			width: 30px;
			height: 50px;
			margin: 10px 32px 0;
		}
		&.icon {
			&-banner {
				&::after {
					background: url("~@/assets/images/icon/ico_banner.png") no-repeat 0 0;
				}
			}
			&-mrec {
				&::after {
					background: url("~@/assets/images/icon/ico_mrec.png") no-repeat 0 0;
				}
			}
			&-interstitial {
				&::after {
					background: url("~@/assets/images/icon/ico_interstitial.png") no-repeat 0 0;
				}
			}
			&-native {
				&::after {
					background: url("~@/assets/images/icon/ico_native.png") no-repeat 0 0;
				}
			}
			&-nativebanner {
				&::after {
					background: url("~@/assets/images/icon/ico_native_banner.png") no-repeat 0 0;
				}
			}
			&-video {
				&::after {
					background: url("~@/assets/images/icon/ico_rewarded_video.png") no-repeat 0 0;
				}
			}
		}
	}
}

//curve-chart
.chartset {
	width: 32.8%;
	aspect-ratio: 2/1;
	margin-bottom: 1%;
	border-radius: 10px;
	border: 1px solid $input;
	.stit {
		font-weight: $fw-bold;
		@include font(12px, $fc-k);
	}
	.data {
		@include font(12px);
	}
	.curve-chart {
		width: 100%;
		aspect-ratio: 5/1;
		padding: 0 10px 10px 10px;
	}
	.info-area {
		padding: 20px 25px;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		.act-data {
			text-align: end;
			.total {
				@include font(24px, $fc-k);
				font-weight: $fw-bold;
			}
		}
	}
}

.lgd {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 12px 20px 12px 140px;
	border-top: 1px solid #e5e5e5;
	.data-set {
		margin-left: 5px;
		.act-data {
			position: relative;
			@include font(22px, $fc-k);
		}
	}
	.remark-set {
		display: flex;
		align-items: top;
		.data-set {
			.act-data {
				@include font(14px);
			}
		}
	}
}

// tooltip
.v-tooltip {
	&-row {
		display: flex !important;
		max-width: fit-content !important;
		margin: 0 !important;
	}
	&__content {
		max-width: 250px;
		word-break: keep-all;
		word-wrap: break-word;
		&.reversed::after {
			content: "";
			position: absolute;
			top: -12px;
			left: calc(50% - 6px);
			border: 6px solid;
			border-color: transparent #313e4e #313e4e transparent;
		}
		&:not(.reversed)::after {
			content: "";
			position: absolute;
			bottom: -12px;
			left: calc(50% - 6px);
			border: 6px solid;
			border-color: #313e4e transparent transparent #313e4e;
		}
	}
	&-icon {
		width: 16px;
		height: 16px;
		margin-left: 2px;
		background: url("~@/assets/images/icon/ico_info.svg") no-repeat 0 0;
		background-size: 100%;
	}
	&-icon2 {
		width: 15px;
		height: 15px;
		margin-left: 2px;
		background: url("~@/assets/images/icon/ico_info2.png") no-repeat 0 0;
		background-size: 100%;
	}
}

//alert-pop
.alert-pop {
	white-space: pre-line;

	&.v-card {
		height: 300px;
		padding: 100px 50px;
		.v-card__text {
			@include font(20px, #000);
			text-align: center;
			.popup-link {
				width: 100%;
				display: inline-flex;
				div {
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}
		}
		.v-card__actions {
			justify-content: center;
			margin-top: 45px;
			.v-btn {
				min-width: 80px;
				height: 33px;
			}
		}
	}

	&.--dense {
		padding: 100px 20px !important;
	}
}

// date range picker
.vue-daterange-picker {
	margin-left: 10px;
	&.input .reportrange-text input{
		min-width: 160px;
	}
	// input
	.reportrange-text {
		display: inline-flex;
		align-items: center;
		min-width: 160px;
		height: 30px;
		border-color: $input !important;
		border-radius: 15px;
		@include font(12px, $fc-g2);
		font-weight: $fw-regular;
		vertical-align: top;
		&::after {
			content: "";
			display: inline-flex;
			flex-shrink: 0;
			align-items: center;
			width: 12px;
			height: 6px;
			margin-left: 5px;
			background: url("~@/assets/images/slct_arr.png") no-repeat 0 0;
			background-size: 100%;
		}
	}
	.daterangepicker {
		td {
			&.active:not(.off),
			&.active:not(.off):hover {
				background-color: $primary !important;
				color: #fff !important;
			}
			&.in-range {
				background: rgba(255, 90, 77, 0.2);
			}
		}
		.ranges {
			li {
				&.active {
					background-color: transparent;
					color: #000;
					&:hover {
						background-color: #eee;
					}
				}
			}
		}
	}
	.drp-buttons {
		.cancelBtn {
			background-color: #7d7d7d;
		}
		.applyBtn {
			background-color: $primary;
		}
	}
	@media all and (max-width: 1280px) {
		.reportrange-text {
			@include font(12px);
		}
	}
}

// date picker form
.date-picker {
	margin-top: 5px;
	box-shadow: none;
	border: 1px solid #ddd;
	border-radius: 4px !important;
	.v-picker {
		@include font(12px);
		&__body {
			width: inherit !important;
		}
	}
}
.v-date-picker {
	&-header {
		padding: 8px !important;
		&__value {
			@include font(12px);
		}
	}
	&-table {
		height: auto !important;
		padding: 0 8px 8px !important;
		th {
			color: $fc-g2 !important;
			font-weight: $fw-regular !important;
		}
		td {
			.v-btn {
				width: 35px !important;
				height: 30px !important;
				border-radius: 4px;
				@include font(12px !important, $fc-k !important);
				font-weight: $fw-regular;
				&--active {
					background: $primary;
					color: #fff !important;
					&::before {
						opacity: 0;
					}
				}
			}
		}
		&__current {
			border: none !important;
			background: rgba(255, 90, 77, 0.2) !important;
			color: #000 !important;
		}

		// month
		&--month {
			td {
				width: 25% !important;
				height: 30px !important;
				padding: 2px 0;
				.v-btn {
					max-width: none !important;
					min-width: fit-content !important;
					&.v-size--default {
						padding: 0 !important;
					}
				}
			}
		}
	}
}
.inp-date {
	height: 32px;
	margin: 0 !important;
	padding: 0 !important;
	border: 1px solid #dedede;
	input {
		@include font(12px, $fc-g2 !important);
	}
	.v-input {
		&__prepend-outer {
			margin: 7px 10px 0 10px;
			.v-input__icon {
				height: auto;
				.v-icon {
					&::before {
						content: "";
						width: 14px;
						height: 16px;
						background: url("~@/assets/images/icon/ico_calendar.png") no-repeat 0 0;
						background-size: 100%;
					}
				}
			}
		}
		&__control {
			height: 30px;
			& > * {
				height: inherit;
			}
		}
		&__slot {
			&::before,
			&::after {
				display: none;
			}
		}
	}
}

.finance2 {
	.v-item-group.v-bottom-navigation {
		justify-content: center;
		height: 0 !important;
		box-shadow: none !important;
	}
	.report-filter-group {
		align-items: center;
		.inp-srch__slct {
			margin-top: 28px !important;
		}
	}
	.payment {
		.btn-box {
			width: 100%;
			display: inline-block;
			text-align: right;
		}
	}
	.no-data {
		padding: 30px 0;
	}
	.invoice {
		.table-set {
			.srch-set {
				align-items: end;
			}
			.btn-set {
				display: flex;
				.v-btn {
					min-width: 90px;
					height: 30px;
					border-radius: 3px;
					@include font(12px);
					&:first-child {
						margin-right: 10px;
					}
				}
			}
		}
	}
	.remittance {
		.srch-set {
			align-items: end;
		}
	}
	.table {
		&.mini-statement {
			table {
				th {
					text-align: right !important;
				}
				td {
					text-align: right !important;
				}
			}
		}
		table {
			td {
				.detail {
					.v-image {
						display: inline-block !important;
					}
				}
			}
		}
	}
	.payment {
		.pop-box {
			width: 100%;
			display: flex;
		}
	}
}
