//buttons
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
input[type=number] {
	-moz-appearance: textfield;
}
.v-btn{
	border-radius: 2px;
	letter-spacing: -1px !important;
	text-transform: none !important;
	&--fab{
		border-radius: 50% !important;
	}
	&--is{
		&-elevated{
			box-shadow: none !important;
		}
	}
	&:not(.v-btn--round).v-size--default{
		padding: 0 20px !important;
	}
	&--text{
		@include font(14px !important, $primary);
		font-weight: $fw-regular !important;
		&::before{
			content: none !important;
		}
	}
	&-toggle{
		&--rounded{
			border-radius: 24px !important;
		}
	}
}
.btn{
	&-edit{
		.v-icon{
			width: 12px !important;
			height: 13px !important;
			background: url('~@/assets/images/btn/btn_edit.png') no-repeat 0 0;
			background-size: 100%;
			color: transparent;
		}
		&:hover{
			&::before{
				opacity: 0 !important;
			}
		}
	}
	&-prev{
		.v-icon{
			width: 6px !important;
			height: 10px !important;
			background: url('~@/assets/images/btn/btn_prev.png') no-repeat 0 0;
			background-size: 100%;
		}
	}
	&-next{
		.v-icon{
			width: 6px !important;
			height: 10px !important;
			background: url('~@/assets/images/btn/btn_next.png') no-repeat 0 0;
			background-size: 100%;
		}
	}
	&-reset{
		color: $fc-g2 !important;
		font-weight: $fw-regular !important;
		text-decoration: underline;
		.v-icon{
			width: 10px !important;
			height: 12px !important;
			margin-right: 4px !important;
			background: url('~@/assets/images/btn/btn_reset.png') no-repeat 0 0;
			background-size: 100%;
		}
	}
	&-reset2{
		font-weight: $fw-regular !important;
		.v-icon{
			width: 18px !important;
			height: 18px !important;
			margin-right: 4px !important;
			background: url('~@/assets/images/btn/btn_reset2.png') no-repeat 0 0;
			background-size: 100%;
		}
	}
	&-copy{
		display: inline-flex;
		align-items: center;
		.v-icon{
			width: 14px !important;
			height: 14px !important;
			background: url('~@/assets/images/btn/btn_copy.png') no-repeat 0 0;
		}
		&::before{
			content: none;
		}
	}
	&-setup{
		display: inline-flex;
		align-items: center;
		.v-icon{
			width: 18px !important;
			height: 18px !important;
			background: url('~@/assets/images/btn/btn_setup.png') no-repeat 0 0;
		}
		&::before{
			content: none;
		}
	}

	&-detail{
		display: inline-flex;
		align-items: center;
		min-width: 50px;
		font-size: 14px;
		.v-image{
			margin-left: 10px;
			&__image{
				background-size: 100% auto !important;
			}
		}
	}
}

.btn-toggle{
	border: 0;
	.v-btn{
		border: 0 !important;
	}
	.btn{
		&-bookmark{
			opacity: 1 !important;
			width: 24px !important;
			height: 24px !important;
			background: url('~@/assets/images/btn/btn_bookmark.png') no-repeat 0 0;
			background-size: 100%;

			&--active{
				background: url('~@/assets/images/btn/btn_bookmark_on.png') no-repeat 0 0;
				background-size: 100%;
			}
		}
		&-chk{
			opacity: 1 !important;
			.v-icon{
				width: 24px !important;
				height: 25px !important;
				background: url('~@/assets/images/btn/btn_chk.png') no-repeat 0 0;
				background-size: 100%;
			}
			&.v-btn--active{
				.v-icon{
					background: url('~@/assets/images/btn/btn_chk_act.png') no-repeat 0 0;
					background-size: 100%;
				}
			}
			&:hover{
				&::before{
					opacity: 0 !important;
				}
			}
		}
	}
	// rounded
	&.rounded{
		height: 30px !important;
		background: $background !important;
		border-radius: 15px !important;
		.v-btn{
			height: inherit !important;
			color: #7D7D7D;
			font-weight: $fw-regular;
			&--active{
				background: $primary;
				border-radius: inherit !important;
				color: #fff !important;
				&::before{
					opacity: 0;
				}
			}
			&:hover{
				border-radius: inherit;
			}
		}
	}
	// tab
	&.tab{
		width: 100%;
		border-bottom: 1px solid #DEDEDE;
		border-radius: 0;
		& > * + *{
			margin-left: 20px !important;
		}
		&:not(.v-btn-toggle--dense){
			.v-btn.v-btn.v-size--default{
				min-width: 30px;
				width: 30px;
				height: 30px;
				padding: 0 !important;
				@include font(0px !important);
			}
		}
		.v-btn{
			margin-bottom: 10px;
			&__content{
				position: relative;
				margin-bottom: -28px;	
			}
			&.v-btn--active{
				position: relative;
				&::before{
					background-color: transparent;
					opacity: 0;
				}
				.v-btn__content{
					&::after{
						content: '';
						position: absolute;
						left: 0;
						bottom: 0;
						width: 100%;
						height: 3px;
						background: $primary;
						opacity: 1;
					}
				}
			}
		}
	}

	// rectangled
	&.rectangled{
		padding: 2px;
		background: $background !important;

		& > * + *{
			margin-left: 2px !important;
		}

		&:not(.v-btn-toggle--dense){
			.v-btn.v-btn.v-size--default{
				min-width: 30px;
				width: 30px;
				height: 30px;
				padding: 0 !important;
				@include font(0px !important);
			}
		}

		.v-btn{
			&.line{
				border-radius: 4px;
				background: url('~@/assets/images/icon/ico_chart_line.png') no-repeat 0 0;
				background-size: 85%;
				background-position: center;
				opacity: 0.3;
			}

      &.stackedline{
				border-radius: 4px;
				background: url('~@/assets/images/icon/ico_chart_stackedline.png') no-repeat 0 0;
				background-size: 85%;
				background-position: center;
				opacity: 0.3;
			}

			&.stack{
				border-radius: 4px;
				background: url('~@/assets/images/icon/ico_chart_stack.png') no-repeat 0 0;
				background-size: 85%;
				background-position: center;
				opacity: 0.3;
			}	

			&.v-btn:hover,
			&.v-btn--active{
				opacity: 1 !important;
				background-color: #fff;
			}
		}
	}

	// set
	&-set{
		width: 100%;
		.v-btn{
			align-items: normal;
			flex: 1;
			height: 100px !important;
			background: none !important;
			border-top: none !important;
			border-right: none !important;
			border-left: 1px solid $input !important;
			opacity: 1 !important;
			&:first-child{
				border-left: none !important;
			}
			&__content{
				max-width: 100%;
				flex-wrap: wrap;
				@include font(12px, $fc-k);
				text-align: left;
				& > *{
					width: 100%;
					line-height: 1;
				}
				.total{
					@include font(14px);
					font-weight: 600;
					line-height: 1;
				}
			}
			&:hover{
				&::before{
					opacity: 1;
				}
			}
			&--active{
				&::before{
					background: none;
					border-bottom: 5px solid;
					opacity: 1;
				}
			}
			&.v-btn--active{
				&::before{
		border-color: white;
				}
			}
			&.v-size--default{
				padding: 20px 20px 15px !important;
			}
		}
	}
}

// finance button
.finance{
	.btn-groups{
		.v-btn{
			height: 32px;
			@include font(12px);
			&:first-child{
				min-width: 52px;
			}
			&:last-child{
				min-width: 120px;
			}
			&.btn-save{
				min-width: 52px;
				height: 32px;
			}
		}
	}
	.btn-set{
		.v-btn{
			min-width: 90px;
			height: 32px;
			@include font(12px);
		}
	}
	.table-set{
		.v-btn{
			min-width: 110px;
			height: 32px;
			@include font(12px);
		}
		&.type2{
			min-width: 150px;
		}
	}
	
}
// popup-btns
.popup-btns{
	&.type2{
		.v-btn{
			min-width: 52px !important;
			height: 32px;
			@include font(14px);
		}
	}
}