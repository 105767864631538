@charset "utf-8";

/*****************************************************************
  scss default set
 *****************************************************************/


/*****************************************************************
  Vuetify default set
 *****************************************************************/
// style guide
$font-family-base: 'KakaoFont','돋움','dotum',sans-serif !default;

/* Primary */
$primary: #FF5A4D !default;

/* Secondary */
$secondary: #A1A1A1 !default;
$secondary-2: #D4D4D4 !default;

$background: #F6F5F4 !default;

/* section color */
$section: #E3E3E3 !default;

/* input color */
$input: #DEDEDE !default;

/* font-color */
$fc-k: #333333 !default;
$fc-k2: #000000 !default;
$fc-r: #FF5A4D !default;
$fc-b: #0084FF !default;
$fc-g1: #767676 !default;
$fc-g2: #A1A1A1 !default;
$fc-g3: #E4E6F0 !default;
$fc-g4: #ABB5BE !default;
$fc-g5: #D4D4D4 !default;

/* point color */
$point-1: #FCE25B !default;
$point-2: #40E5DF !default;
$point-3: #0084FF !default;
$point-4: #5B49E0 !default;

/* font default */
$font-size-base: 16px !default;
$font-color-base: $fc-k !default;
$input-placeholder-color: $fc-g1 !default;

/* font-weight */
$fw-bold: 700 !default;
$fw-regular: 400 !default;

// btn
$btn-font-weight: 400 !default;
$btn-sizes: (
  'x-small': 20,
  'small': 25,
  'default': 30,
  'large': 35,
  'x-large': 40
);
$btn-font-sizes: (
  'x-small': 10px,
  'small': 12px,
  'default': 14px,
  'large': 14px,
  'x-large': 14px
);
$fab-sizes: (
  'x-small': 24,
  'small': 30,
  'default': 40,
  'large': 45,
  'x-large': 50
);

// switch
$switch-dirty-offset-x: 13px !default;
$switch-track-inset-opacity: 1 !default;
$switch-track-inset-width: 30px !default;
$switch-thumb-height: 9px !default;
$switch-thumb-width: 9px !default;
$switch-thumb-top: calc(50% - 12px) !default;
$switch-track-inset-width: 30px !default;
$switch-track-inset-height: 15px !default;

// chip
$icon-sizes: (
  'x-small': (
    'font-size': 10,
    'height': 15
  ),
  'small': (
    'font-size': 10,
    'height': 20
  ),
  'default': (
    'font-size': 10,
    'height': 25
  ),
  'large': (
    'font-size': 12,
    'height': 30
  ),
  'x-large': (
    'font-size': 14,
    'height': 40
  )
);
$chip-filter-max-width: auto !default;
$chip-padding: 0 10px !default;

// tab
$tabs-item-padding: 0 20px !default;
$tab-font-size: 14px !default;
$tab-font-weight: 700 !default;
$tabs-bar-background-color: none !default;
$tabs-bar-height: 40px !default;

// tooltip
$tooltip-background-color: #313E4E !default;
$tooltip-text-color: #fff !default;
$tooltip-opacity: 1 !default;
$tooltip-border-radius: 0 !default;
$tooltip-font-size: 12px !default;

// v-dialog
$dialog-border-radius: 10px !default;
/*----------------------------------------------------------------
  Input - text-field
-----------------------------------------------------------------*/
$input-font-size: 13px !default;
$input-icon-width: 10px !default;
$input-icon-min-width: 10px !default;
$text-field-border-radius: 2px !default;
$text-field-solo-control-min-height: 30px !default;
$text-field-outlined-rounded-slot-padding: 0 20px !default;

// outlined
$text-field-filled-full-width-outlined-slot-min-height: 30px !default;
$text-field-outlined-rounded-slot-padding: 0 20px !default;
$text-field-enclosed-details-padding: 0 20px !default;
$text-field-outlined-fieldset-border: 1px solid #FF5A4D !default;

// select
$select-selections-line-height: inherit !default;

// tab
// $tabs-item-max-width: fit-content !default;
$tabs-item-min-width: fit-content !default;

// breadcrumbs
$breadcrumbs-padding: 0 !default;

/* 마진 / 패딩 설정 */
@for $i from 1 through 100 {  
	.mt#{$i*10} {
		margin-top: #{$i*10}px !important;
	}
	.pt#{$i*10} {
		padding-top: #{$i*10}px !important;
	}
}

// 텍스트 정렬 cho
.at{
  text-align: center !important;
}
.al{
  text-align: left !important;
}
.ar{
  text-align: right !important;
}
.ac{
  text-align: center !important;
}

.brtn{
  border-top: 0 !important
}
.brbn{
  border-bottom: 0 !important
}


.v-application{
  font-family: 'KakaoFont','돋움','dotum',sans-serif !important;
  .title{
    font-family: 'KakaoFont','돋움','dotum',sans-serif !important;
  }
  &--is-ltr .v-list-item__action:first-child,
  &--is-ltr .v-list-item__icon:first-child{
    margin-right: 15px !important;
  }
  .error--text{
    color: #FF5A4D !important;
    caret-color: #FF5A4D !important;
  }
  a{
    color: inherit;
  }
}

.v-application ul, .v-application ol{
  padding-left: 0 !important;
}

// theme-light
.theme--light{
  &.v-btn{
    color: #fff;
  }
  &.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset{
    color: #DEDEDE !important;
  }
  &.v-input{
    & input,
    & textarea{
      color: $fc-k;
    }
    &--is-disabled{
      .v-input__slot{
        input, textarea{
          color: #A1A1A1 !important;
        }
      }
    }
  }
}

// btn
.v-btn__content{
  letter-spacing: 0;
}
// icon - search
.mdi-magnify{
  &::before{
    content: '';
    width: 14px;
    height: 14px;
    background: url('~@/assets/images/icon/ico_srch.png') no-repeat 0 0;
    background-size: 100%;
  }
}

// table
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td{
  font-size: 11px;
  .negative {
    color: red!important;
  }
}

.v-ripple__container{
  display: none !important;
}

// dialog
.v-dialog{
  background: #fff;
  &--fullscreen > .v-card{
    min-width: 1000px;
  }
}

// text field
.v-text-field__details{
  padding: 5px 5px 0 !important;
}

// select
.v-select__selection--comma{
  color: $fc-k;
}

 // chip
 .v-chip{
  &.secondary{
     &.theme--light{
        border-color: #d4d4d4 !important;
     }
  }
}

.v-chip-group{
	.v-slide-group__content{
		padding: 0;
	}
	.v-chip{
		margin: 2px 6px;
	}
}

// common
// align
.al{
  text-align: left !important;
  justify-content: flex-start !important;
}
.ar{
  text-align: right !important
}
.ac{
  text-align: center !important;
}
.row{
  display: flex !important;
}
// border none
.bdn{
  border: none !important;
}
// width
.full-width{
  width: 100% !important;
}
// font color
.fc-r{
  color: #FF5A4D !important; 
}
.fc-bk{
  color: #333333!important; 
}
.fc-g{
  color: #A1A1A1 !important;
}
.fc-gr{
  color: #00AF46 !important;
}
.fc-sb{
  color: #00A3FF !important;
}
.fc-y{
  color: #FFBE0A !important;
}
// 필수
.nec{
  &::after{
    content: '*';
    display: inline-flex;
    margin-left: 5px;
    font-size: 14px;
    font-weight: 700;
    color: #FF5A4D;
  }
}
//link
.txt-link{
  color : #0500FF !important;
  text-decoration: underline !important;
}
// max 2 line
.max-2{
  display: -webkit-box !important;
  overflow: hidden !important;
  max-width: 100% !important;
  max-height: 2.8em !important;
  line-height: 1.4em !important;
  text-overflow: ellipsis !important;
  white-space: normal !important;
  word-wrap: break-word !important;
  word-break: break-all !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  &.v-input{
    height: inherit !important;
      label{
      display: -webkit-box !important;
      max-height: 2.4em !important;
      line-height: 1.2em !important;
      word-break: break-word !important;
      -webkit-line-clamp: 2 !important;
      -webkit-box-orient: vertical !important;
      font-weight: $fw-regular;
    }
  }
}