@charset 'utf-8';

@font-face {
   font-family: 'KakaoFont';
   font-style: normal;
   font-weight: 100;
   src: local('KakaoLight'), local('KakaoLight');
   src: url('~@/assets/font/KakaoLight.eot?#iefix') format('embedded-opentype');
   src: url('~@/assets/font/KakaoLight.eot');
   src: url('~@/assets/font/KakaoLight.woff2') format('woff2'),
   url('~@/assets/font/KakaoLight.woff') format('woff');
}
   

@font-face {
   font-family: 'KakaoFont';
   font-style: normal;
   font-weight: 400;
   src: local('KakaoRegular'), local('KakaoRegular');
   src: url('~@/assets/font/KakaoRegular.eot?#iefix') format('embedded-opentype');
   src: url('~@/assets/font/KakaoRegular.eot');
   src: url('~@/assets/font/KakaoRegular.woff2') format('woff2'),
   url('~@/assets/font/KakaoRegular.woff') format('woff');
}

@font-face {
   font-family: 'KakaoFont';
   font-style: normal;
   font-weight: 700;
   src: local('KakaoBold'), local('KakaoBold');
   src: url('~@/assets/font/KakaoBold.eot?#iefix') format('embedded-opentype');
   src: url('~@/assets/font/KakaoBold.eot');
   src: url('~@/assets/font/KakaoBold.woff2') format('woff2'),
   url('~@/assets/font/KakaoBold.woff') format('woff');
}

