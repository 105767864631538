// tables
.table{
	width: 100%;
	border-top: 1px solid $secondary;
	border-radius: 0 !important;
	table{
		thead{
			background: #F6F6F6;
			font-weight: $fw-bold;
		}
		tbody{
			th{
				font-weight: $fw-regular;
			}
		}
		table-layout: fixed;
		tr{
			// total
			&.total{
				th{
					border-bottom: 1px solid $secondary !important;
					font-weight: $fw-regular;
				}
			}
			&.v-data-table__progress{
				.v-alert{
					margin: 0;
				}
				.v-alert__wrapper{
					text-align: center;
				}
			}
			&.v-data-table__selected{
				background: #fff !important;
			}
			&:last-child{
				border-bottom:thin solid rgba(0, 0, 0, 0.12)
			}
		}
		th{
			padding: 0 10px !important;
			@include font(12px !important, $fc-k !important);
			vertical-align: middle;
		}
		td{
			height: auto !important;
			padding: 10px 10px !important;
			@include font(12px !important, $fc-k !important);
			word-break: break-all;
			vertical-align: middle;
			.txt-ellipsis{
				display: block;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap !important;
				width: 80%;
			}
			.div-row{
				display: flex;
				align-items: center;
				justify-content: space-between;
				.tb-link{
					display: block;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap !important;
					width: 50%;
				}
				.app{
					display: block;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap !important;
					width: 50%;
					margin-left: 10px;
				}
			}
			.tb-title{
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				p{
					margin: 0 !important;
					&.s-tit{
						width: 100%;
						margin-top: 5px !important;
						@include font(11px !important, $fc-g2);
					}
				}
				.tit{
					color: $fc-k;
					&-info{
						width: 100%;
						margin-top: 10px;
						@include font(11px, $fc-g2);
						li{
							margin-top: 5px;
							&:first-child{
								margin-top: 0;
							}
							strong{
								display: block;
								width: 100%;
								padding-right: 2px;
								color: $fc-k;;
								font-weight: $fw-regular !important;
							}
						}
					}
				}
				
				// btn-set
				&.edit{
					p{
						&.tit{
							width: calc(100% - 35px);
							@include ellipsis;
						}
					}
					.v-btn{
						margin-left: auto;
					}
				}
			}
			.tb-link{
				@include ellipsis;
				color: #0500FF;
				text-decoration: underline;
			}
			.tb-link-none{
				@include ellipsis;
				color: #000;
				text-decoration: none;
			}
			.tb-count{
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				.tit{
					max-width: 60% !important;
					@include ellipsis;
				}
				.num{
					padding-left: 10px;
				}
			}
			.inp{
				input{
					text-align: center !important;
					@include font(12px);
				}
				&.v-input{
					max-width: fit-content !important;
					min-height: fit-content !important;
					.v-input__slot{
						min-height: 30px !important;
						padding: 0 8px !important;
					}
				}
				&.full-width{
					max-width: 100% !important;
				}
				&.btn-type{
					max-width: calc(100% - 24px) !important;
				}
				&.al{
					input{
						text-align: left !important;
					}
				}
				&.ar{
					input{
						text-align: right !important;
					}
				}
				&.fc-r{
					input{
						color: $fc-r;
					}
				}
        &.negative{
          input {
            color: red !important;
          }
        }
			}
			.v-label{
				@include font(12px !important);
				font-weight: $fw-regular;
			}
			.v-btn.v-size--default{
				padding: 0 8px !important;
				@include font(12px !important);
				font-weight: $fw-regular !important;
			}
			.v-btn.v-btn--text{
				@include font(14px !important, $fc-k);
				font-weight: $fw-regular !important;
				&::before{
					content: none !important;
				}
				&.btn-add{
					&.added{
						color: $primary !important;
					}
				}
			}
			.tb-edit-set{
				display: flex;
				align-items: center;
				justify-content: space-between;
				.btn-set{
					display: flex;
					align-items: center;
					width: 50px;
					.v-btn{
						&::before{
							content: none;
						}
					}
				}
			}
			.tb-thumb{
				width: calc(100% - 50px);
				.tb-link{
					display: flex;
					align-items: center;
					.icon{
						flex-shrink: 0;
						max-width: 36px;
						width: 36px;
						height: 36px;
						margin-right: 10px;
						background: #EBE9E4;
						border-radius: 8px;
					}
					.txt{
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						width: 100%;
						text-decoration: underline;
						&.fc-org{
							color: $primary;
						}
					}
				}
				.tb-link-none{
					display: flex;
					align-items: center;
					.icon{
						flex-shrink: 0;
						max-width: 36px;
						width: 36px;
						height: 36px;
						margin-right: 10px;
						background: #EBE9E4;
						border-radius: 8px;
					}
					.txt{
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						width: 100%;
						text-decoration: none;
						&.fc-org{
							color: #000;
						}
					}
				}
			}
			.more-set{
				display: flex;
				align-items: center;
				justify-content: left;
				.v-btn{
					margin-left: 5px;
					.mdi{
						&-dots-vertical{
							&::before{
								content: "\F01D9";
								color: $fc-g1;
							}
						}
					}
				}
			}
			.tb-switch{
				&.v-input--is-disabled{
					.v-input__slot{
						background: none !important;
					}	
				}
				.v-input__slot{
					justify-content: center;
				}
				
			}
			.v-input--switch{
				.v-input__control{
					.v-input__slot{
						justify-content: center;
					}
				}
			}
			&.fc-gr{
				color: #00AF46 !important;
			}
			&.fc-g {
				color: #A1A1A1 !important;
			}
			&.fc-bk {
				color: #333333!important; 
			}
		}
		.v-data-table__checkbox{
			.v-icon{
				&.mdi-checkbox-marked{
					color: $primary;
				}
			}
			.v-input--selection-controls__ripple{
				display: none;
			}
		}
	}
	// table set
	&-set{
		display: flex;
		align-items: baseline;
		justify-content: space-between;
		width: 100%;
		.tb-con{
			.tb-tit{
				display: flex;
				align-items: center;
				justify-content: flex-start;
				.slct{
					max-width: 260px;
					margin-left: 25px;
					border: 1px solid $input;
					&.result{
						.v-select__selections{
							input{
								color: $fc-k !important;
							}
						}
					}
					&.en{
						max-width: 250px;
					}
				}
				.setup{
					margin-left: 10px;
					cursor: pointer;
				}
			}
			&.ver2{
				width: calc(50% - 20px);
				.tb-tit{
					justify-content: space-between;
				}
				.rgt{
					@include font(14px, $secondary);
					cursor: pointer;
				}
			}
		}
		.result-area{
			height: auto !important;
			margin: 0 !important;
			.v-list{
				border: none !important;
			}
			.v-list-item {
				justify-content: flex-start;
				border: none !important;
				&.v-list-item--active{
					background: transparent !important;
					.v-list-item__title{
						font-weight: $fw-regular !important;
					}
				}
			}
			.theme--light{
				&:not(.v-list-item--active){
					color: transparent !important;
				}
				&:not(.v-list-item--disabled){
					color: transparent !important;
				}
				
			}
			
		}
		.btn-set{
			display: flex;
			align-items: center;
			.v-btn{
				margin-left: 10px;
				&:first-child{
					margin-left: 0;
				}
			}
		}
		&.type2{
			align-items: flex-end;
			.btn-set{
				margin-bottom: 10px;
			}
		}
	}
	// header bg
	&.th-bg{
		th{
			height: 50px !important;
			background: #F6F5F4;
			border-color: $section !important;
		}
		td{
			border-color: $secondary !important;
		}
	}
	// table hover
	&.bg-hover{
		table{
			overflow: hidden;
			td,
			th{
				z-index: 1;
				position: relative;
			}
			td:hover{
				z-index: 0;
				// row
				&::before {
					z-index: -1;
					content:'';
					position: absolute;
					top: 0;
					left: -5000px;
					width: 10000px;
					height: 100%;
					// background: rgba(0, 0, 0, 0.1);
				}
				// column
				&::after {
					z-index: -2;
					content:'';
					position: absolute;
					top: -5000px;
					left: 0;
					width: 100%;
					height: 10000px;
					background: rgba(0, 0, 0, 0.05);
			 	}
			}
		}
	}
	//table none-line
	&.none-line{
		padding: 13px 0 !important;
		border-bottom: 1px solid $section !important;
		table{
			tr{
				&:last-child{
					border-bottom: none !important;
				}
			}
			td{
				padding: 7px 10px !important;
				border-bottom: none !important;
			}
		}
	}
	&-wrap{
		width: 100%;
		margin-top: 50px;
		&:first-child{
			margin-top: 0;
		}
		.table-info{
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			margin-bottom: 20px;
			.tit{
				margin: 0;
				@include font(14px);
				font-weight: $fw-bold;
			}
		}
	}
	&.at{
		td{
			vertical-align: top;
		}
	}

	// payment table
	&.payment-table{
		table{
			tr{	
				&:first-child{
					td{
						@include font(22px !important);
					}
				}
				&.result{
					border-top: 1.1px solid #909090;
					 border-bottom: 1.1px solid #909090;
					
				}
				&.top-line{
					border-top: 1.1px solid #909090;
				}
				&.btm-line{
					 border-bottom: 1.1px solid #909090;
				}
				&.btm-line2{
					border-bottom: 1.2px solid #d5d5d5!important;
			  }
			}
			th{
				background: #fff;
			}
			td{
				@include font(14px !important, $fc-k !important);
				text-align: right;
				word-break: keep-all;
				&.subject{
					vertical-align: top;
					text-align: left;
					
				}
				&.live-per{
					text-align: left;
				}
				&.gry{
					color: $fc-g2 !important;
				}
				&.none-subject{
					border-bottom: none !important;
					vertical-align: top;
					text-align: left;
				}
			}
		}
	}	

	// data table
	.v-data{
		&-table{
			// expand
			&__expand{
				&-icon{
					&::before{
						content: '▼';
						display: flex;
						align-items: center;
						justify-content: center;
						width: 25px;
						height: 25px;
						@include font(12px, $primary);
					}
					&--active{
						&::before{
							color: $secondary;
						}
					}
				}
			}
			&__expanded__content{
				background: $background;
				box-shadow: none !important;
			}
		}
		&-footer{
			width: 100%;
			padding: 5px 0 0 !important;
			border-top: none !important;
			line-height: 1 !important;
			&__select{
				margin: 0 0 0 auto !important;
				@include font(12px, $fc-k);
				.v-select{
					margin: 0 0 0 10px !important;
				}
				.v-input{
					&__control{
						min-width: 60px;
						background: #fff;
						box-sizing: border-box;
						border-radius: 8px;
						& > .v-input__slot{
							padding: 0 10px;
							&::before{
								border: none !important;
							}
						}
					}
					&__icon{
						.v-icon::before{
							content: '';
							width: 10px;
							height: 5px;
							background: url('~@/assets/images/icon/slct_arr.png') no-repeat 0 0;
							background-size: 100%;
						}
					}
					&.primary--text{
						color: transparent !important;
						caret-color: transparent !important;
					}
				}
				.v-application .primary--text{
					color: $secondary !important;
					caret-color: $secondary !important;
				}
			}
			&__icons{
				&-before,
				&-after{
					.v-btn{
						margin: 0 !important;
					}
					.theme--light.v-btn.v-btn--disabled .v-icon, .theme--light.v-btn.v-btn--disabled .v-btn__loading{
						color: $secondary !important;
					}
				}
				&-before{
					margin-right: 10px;
				}
			}
		}
	}
	// simple table
	&.type2{
		line-height: 2.3;
		table{
			th{
				border-right: 1px solid $section;
				border-bottom: 1px solid $section;
				text-align: center !important;
			}
			thead{
				tr{
					&:first-child{
						border-top: 2px solid $fc-k;
						th{
							&:last-child{
								border-right: none;
							}
						}
					}
				}
			}
			td{
				@include ellipsis;
				display: table-cell;
				border-left: 1px solid $section;
				text-align: center;
				min-height: 50px !important;
				&:first-child{
					border-left: none;
				}
			}
		}
	}
	&.type3{
		table{
			th{
				height: 40px !important;
				padding: 2px 5px !important;
			}
			td{
				height: 40px !important;
				padding: 2px 5px !important;
				&.pa-0{
					padding: 0 !important;
				}
			}
		}
	}

	// expanded
	&-expanded{
		table{
			td{
				background: $background;
				border: none !important;
			}
		}
	}

	&.version3{
		table{
			th{
				height: 36px !important;
				background: #fff;
			}
		}
	}

	&.thColor{
		table{
			th{
				background: #F6F6F6;
			}
		}
	}

	// sdk 팝업 테이블 높이 고정
	&.h-sdk{
		.v-data-table__wrapper{
			max-height: 440px;
		}
	}

	// app-ads 테이블 높이고정
	&.h-ads{
		.v-data-table__wrapper{
			max-height: calc(100vh - 260px);
		}
	}

	// setting / app-ads 테이블 높이고정
	&.h-set{
		.v-data-table__wrapper{
			max-height: calc(100vh - 260px);
		}
	}

	&.v-data-table--fixed-header{
		table{
			th{
				background: #F6F6F6 !important;
			}
		}
	}

	&.v-data-table--fixed-header > .v-data-table__wrapper > table > thead > tr > th{
		border-bottom: inherit !important;
		position: initial;
		z-index: inherit;
	}
	&.v-data-table--fixed-header > .v-data-table__wrapper > table > thead{
		border-bottom: 0px !important;
		position: sticky !important;
		top: 0 !important;
		z-index: 2 !important;
	}

	&.inner{
		border-top: 0 !important;
		table{
			min-width: 100% !important;
			tr{
				&:last-child{
					border-bottom: 0;
				}
			}
		}
	}
	
	@media all and (max-width:1280px){
		table{
			th{
				@include font(11px !important);
			}
			td{
				@include font(11px !important);
				.tb-title{
					p{
						&.s-tit{
							@include font(10px !important);
						}
					}
					.tit{
						&-info{
							@include font(10px);
						}
					}
				}
				.tb-count{
					.num{
						padding-left: 5px;
					}
				}
				.inp{
					input{
						@include font(10px !important);
					}
				}
			}
		}
		&-wrap{
			.table-info{
				.tit{
					@include font(12px);
				}
			}
		}
		// data table
		.v-data{
			&-footer{
				width: 100%;
				padding: 5px 0 0 !important;
				border-top: none !important;
				&__select{
					@include font(11px);
				}
				&__icons{

					&-before{
						margin-right: 10px;
					}
				}
			}
		}
	}

}

.tb-top{
	padding: 20px 0 10px;
	.v-input--checkbox{
		.v-label{
			@include font(13px !important, $fc-k !important);
			font-weight: $fw-regular;
		}
	}
}

.tb-area{
	.tb-top{
		.subtit{
			height: 0.8em;
			@include font(16px);
		}
		.top-set{
			display: flex;
			align-items: center;
			justify-content: space-between;
			.top-rgt{
				.srch-set{
					border: none;
					.slct{
						&:last-child{
							margin-right: 0 !important;
						}
					}
					@media all and (max-width:1600px){
						.inp-srch{
							min-width: 150px;
						}
						.slct{
							width: 140px;
							.v-input__control{
								width: 160px;
							}
						}
					}
					@media all and (max-width:1350px){
						// flex-wrap: nowrap;
						.v-input {
							font-size: 11px !important;
					 	}
						.slct{
							width: 120px;
							.v-input__control{
								width: 140px;
							}
						}
					}
				}
			}
			.top-lft{
				.app-id{
					display: flex;
					align-items: center;
					li{
						align-items: center;
						.tit{
							width: 120px;
							@include font(14px, $fc-k );
							font-weight: $fw-bold;
						}
						.con{
							@include font(14px , $fc-k );
						}
						&:last-child{
							margin-left: 30px;
						}
						@media all and (max-width:1860px){
							.tit{
								width: 105px;
							}
							&:last-child{
								margin-left: 20px;
							}
						}
						@media all and (max-width:1760px){
							.tit{
								width: 100px;
							}
							&:last-child{
								margin-left: 10px;
							}
						}
						@media all and (max-width:1480px){
							.tit{
								width: 95px;
								@include font(12px);
							}
							.con{
								@include font(12px);
							}
						}
						@media all and (max-width:1350px){
							.tit{
								width: 80px;
							}
							
						}
					}
					@media all and (max-width:1760px){
						margin-right: 10px;
					}
					@media all and (max-width:1280px){
						margin-right: 20px;
					}
				}
			}
		}
	}
	.tb-con{
		.v-btn.guide{
			min-width: 70px !important;
			&:last-child{
				margin-left: 15px;
			}
			&.btn-copy{
				min-width: 24px !important;
				margin-left: 10px;
			}
		}
		.table{
			.rgtbtn{
				margin-left: 15px;
			}
		}
	}
	&.ver2{
		padding-bottom: 20px;
		.form-list{
			display: flex;
			align-items: center;
			margin-left: 5px;
			li{
				width: auto;
				&:last-child{
					margin-left: 70px;
				}
				.tit{
					width: 80px;
					@include font(14px , #000);
					font-weight: $fw-bold;
				}
				.con, .v-select{
					margin-left: 20px;
					@include font(14px , #000);
					font-weight: $fw-regular;
				}
			}
		}
	}
}

//chart-view
.chart-view{
	.tb-con{
		margin-top: 60px;
		.refer{
			margin-top: 20px;
			@include font(12px ,$fc-g2);
		}
	}
	.table-set{
		.table{
			th{
				@include font(14px !important);
			}
			td{
				@include font(14px !important);
			}
		}
		&.en{
			align-items: flex-start;
		}
	}
	
}

//loading
.loading-pop{
	.v-alert__content{
		text-align: center;
	}
}
.loading-txt{
	margin-top: 15px;
	@include font(22px, $fc-k);
	font-weight: $fw-bold;
}

.load-fail{
	position: relative !important;
	left: 0 !important;
	top: 0 !important;
	transform: inherit !important;
}

//app-setting
.app-setting{
	position: relative !important;
	left: 0 !important;
	top: 0 !important;
	transform: inherit !important;
}

//finance
.finance{
	.sub-title-1{
		margin-top: 45px;
		@include font(16px !important);
		&:first-child{
			margin-top: 0;
		}
	}
	.sub-title-2{
		font-weight: $fw-bold;
		@include font(16px !important,$fc-k !important);
	}
	.v-tabs-bar{
		margin-bottom: 20px;
	}
	.tb-area{
		padding: 10px 0;
		li{
			margin-left: 0 !important;
			.tit{
				width: auto !important;
				@include font(16px !important);
			}
			.con{
				width: auto;
				margin-left: 10px;
			}
		}
	}
	.btn-groups{
		position: sticky;
		bottom: 0;
		margin-top: 20px;
		.btn{
			border-radius: 5px;
		}
		.v-size--default {
			@include font(12px !important);
		}	
	}
	.company-report{
		.table-set{
			padding: 5px 0;
			.lft{
				display: flex;
				align-items: center;
				.sub-title-2{
					margin: 15px 15px 15px 0;
				}
			}
		}
	
	}
	.paging{
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding: 20px 0;
		.next{
			margin-left: 20px;
		}
	}
	.datepicker{
		width: 160px;
	}
	.table{
		table{
			tr{
				&.bgType2{
					th{
						background: #F6F5F4 !important;
						&.bgWhite{
							text-align: left !important;
							background: #fff !important;
						}
					}
				}
				&.bgType3{
					th{
						background: #e5e5e5 !important;
					}	
				}
				&.bgType4{
					th{
						background: #ccc !important;
					}	
				}
				&.total{
					th{
						background: #fff !important;
					}
				}
			}
			th{
				@include font(14px !important);
			}
			td{
				@include font(12px);
				.v-btn{
					min-width: 70px;
					&.more{
						min-width: 5px;
					}
				}
				.v-btn-toggle{
					.btn-chk{
						&.v-btn--active{
							color: transparent !important;
						}
					}
				}
				.btn-text{
					.v-btn{
						background: none;
						border: 0 !important;
						@include font(15px !important);
						color: $fc-k;
						&::before{
							content: none;
						}
						&.fixed{
							color: $primary;
						}
					}
					&.sm{
						.v-btn{
							@include font(14px !important);
						}
					}
				}
				.btn-transparent {
					.v-btn{
						&--disabled{
							background-color:rgba(0, 0, 0, 0)!important
						}
					}
				}
				.inp-date{
					display: inline-flex;
					width: 130px;
					&.same{
						input{
							color: #00A3FF !important;
						}
						.v-input__icon{
							.v-icon{
								&::before{
									background: url('~@/assets/images/icon/ico_calendar_b.png') no-repeat 0 0;
								}
							}
						}
					}
					&.different{
						input{
							color: #FFBE0A !important;
						}
						.v-input__icon{
							.v-icon{
								&::before{
									background: url('~@/assets/images/icon/ico_calendar_y.png') no-repeat 0 0;
								}
							}
						}
					}
				}
				div.comp-date{
					@include font(9px !important);
				}
			}
			
		}
		.inp-rate{
			display: flex;
			align-items: center;
			justify-content: space-between;
			.rate{
				flex-shrink: 0;
				margin-right: 8px;
			}
			.inp{
				min-height: 25px !important;
				border-radius: 5px;
				input{
					text-align: right !important;
				}
			}
		}
		&.type4{
			table{
				thead{
					tr{
						&:first-child{
							border-top: 1.1px solid $secondary;
						}
					}
				}
				th{
					background: #fff !important;
					font-weight: $fw-regular;
					@include font(14px !important);
				}
				td{
					font-weight: $fw-bold;
					@include font(16px !important);
				}
			}
		}
		&.center{
			table{
				th,
				td{
					text-align: center !important;
					&.al{
						text-align: left !important;
					}
					&.ar{
						text-align: right !important;
					}
				}
				thead{
					th{
						&.al{
							text-align: left !important;
						}
						&.ar{
							text-align: right !important;
						}
					}
				}
			}
		}
		&.owner{
			table{
				tr{
					&:last-child{
						border-bottom: 1px solid $section;
					}
				}
				th{
					padding: 20px 10px !important;
					border-bottom: 1px solid $section !important;
				}
				td{
					padding: 20px 10px !important;
				}	
			}
		}
		&.none-line{
			td{
				.tit{
					font-weight: $fw-bold;
					@include font(14px);
					@media all and (max-width: 1380px){
						@include font(13px);
					}
				}
				.con{
					@include font(14px);
					.txt-link{
						color: $primary !important;
					}
				}
			}
		}
		&.bank-info{
			td{
				.con{
					@include font(14px);
					@media all and (max-width:1380px){
						@include font(13px);
					}
				}
			}
		}
		
		&.month-rate{
			.data{
				min-width: 70px;
			}
		}
			&.adNet{
				table{
					width: 3500px;
					.negative {
						color: red!important;
					}
				}
			}	
		
		&.w1800{
			table{
				min-width: 1800px;
				max-width: 100%;
        height: 100px;
			}
		}
		&.w1600{
      p{
				width: 100%;
        padding:150px 0 150px 0;
        vertical-align: middle;
        text-align: center;
      }
			table{
				min-width: 1600px;
				max-width: 100%;
			}
		}
		&.type5{
			table{
				td{
					width: 100%;
					&:nth-child(2){
						.v-input{
							display: inline;
							input{
								text-align: right !important;
							}
						}
					}
				}
			}
		}
	}
	.invoice{
		.table{
			&.type3{
				td{
					width: 100%;
					.v-input{
						display: inline-block;
						&.inp-date{
							display: inline-flex;
						}
					}
          &.negative {
            color: red!important;
          }  
				}
			}
		}
	}
	.remittance{
		.table-set{
			justify-content: flex-start;
			.sub-title-2{
				padding: 5px 0 !important;
			}
			.v-btn{
				margin-top: 10px !important;
			}
		}
	}
}

//invoice-table
.invoice-con{
	.sub-title-2{
		margin-bottom: 15px;
		font-weight: $fw-bold;
		color: #000;
	}
	.table{
		padding-bottom: 40px;
		&.version2{
			th{
				text-align: center !important;
				&:first-child{
					text-align: left !important;
				}
			}
			td{
				text-align: center !important;
				&:first-child{
					text-align: left !important;
				}
			}
		}
		&.version3{
			th{
				border-bottom: none !important;
				text-align: center !important;
			}
			td{
				text-align: center !important;
			}
		}
	}
	.transfer-area{
		display: flex;
		justify-content: flex-end;
		.transfer-box{
			width: 350px;
			margin-left: 30px;
			padding: 25px 0 15px 0;
			border-top: 1px solid $fc-g2;
			border-bottom: 1px solid $fc-g2;
			.sub-title-1{
				padding-bottom: 10px;
				@include font(16px);
			}
			.form-list{
				.con{
					font-weight: $fw-bold;
					justify-content: flex-end;
				}
				&.ver2{
					li{
						border-bottom: 1px solid #e4e4e4;
						line-height: 2;
						&:last-child{
							padding-bottom: 0;
							border-bottom: none;
						}
						&.top-line{
							border-top: 1px solid $fc-g2;
						}
						&.blank{
							padding: 24px 0!important;
							@media all and (max-width:1280px){
								padding: 19px 0!important;
							}
						}
						@media all and (max-width:1280px){
							line-height: 1.5;
						}
					}
					.tit{
						width: 200px;
						font-weight: $fw-regular;
						&.point{
							font-weight: $fw-bold;
						}
					}
					.con{
						width: calc(100% - 200px);
						font-weight: $fw-regular;
						&.point{
							font-weight: $fw-bold;
						}
					}
				}
			}
		}
	}
	.btn-groups{
		position: inherit;
		.v-btn{
			min-width: 160px;
			height: 40px;
		}
	}
	.footer-logo{
		display: flex;
		justify-content: center;
		padding: 60px 0;
	}	
}

