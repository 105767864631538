html {
   overflow-x:auto !important;

   &.overflow-y-hidden{
	   overflow-y: hidden !important
   }
   &.overflow-hidden{
	   overflow: hidden !important
   }
}


// layout custom
.v-application--wrap {
   min-width: 1260px;
   min-height: 720px;
   flex-direction: row !important;
}

#container{
   width: calc(100% - 80px);
   padding: 20px;
	.content-section{
		// border: 1px solid $input;
	}
	.btn-toggle-set{
		border-right: 1px solid $input;
		border-left: 1px solid $input;
		border-top: 2px solid #000;

		// .v-btn{
		// 	width: 12.5%;
		// }
	  }
	&.full{
		width: 100%;
	}
}

#container-nopadding{
	width: calc(100% - 80px);
   padding: 20px 20px 20px 0px;
	.content-section{
		// border: 1px solid $input;
    .btn-toggle-set{
      border-right: 1px solid $input;
      border-left: 1px solid $input;
      // border-right: 1px solid $input;
    }
	}
	&.full{
		width: 100%;
	}
}

#footer{
	position: absolute;
	bottom: 3%;
	left: 50%;
	transform: translate(-50%,-50%);
	.copyright{
		display: flex;
		align-items: center;
		@include font(12px !important, $fc-g2);
		line-height: 1;
		.footer-logo{
			padding: 0 8px;
		}
	}
}

// login form
.login-area{
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 400px;
	text-align: center;
	.login-logo{
		width: 100%;
		.v-image{
			margin: 0 auto !important;
		}
	}
	.login-form{
		margin-top: 40px;
		.login-set{
			.inp-login{
				.inp{
					width: 420px;
					margin: 0 auto;
					border-radius: 4px;
					.v-input__slot{
						min-height: 40px;
					}
					&.password{
						margin-top: 20px;
					}
				}
				.login-btn{
					width: 100%;
					height: 40px;
					margin-top: 20px;
					background: #7D7D7D;
					letter-spacing: 0px !important;
					text-transform: none;
				}
			}
			.google-login{
				width: 100%;
				.add-txt{
					padding: 10px 0;
					@include font(14px);
				}
				.google-btn{
					width: 100%;
					height: 40px;
					padding: 0 !important;
					background: none !important;
					.v-btn__content{
						width: 100%;
						height: 100%;
						line-height: 40px;
						background: #4285F4 !important;
						@include font(14px);
						font-weight: $fw-bold;
						letter-spacing: 0;
						text-transform: none;
					}
					&::before{
						content: '';
						z-index: 1;
						@include center(vertical);
						left: 0;
						width: 40px;
						height: 40px;
						background: url('~@/assets/images/btn/btn_google.png') no-repeat 0 0;
						background-size: 100%;
						opacity: 1;
					}
					&:hover{
						transform: none;
						opacity: 1 !important;
					}
				}
			}
		}
		.forget-set{
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 20px;
      display: flex;
			.btn-help{
				padding: 0 17px;
				background: url('~@/assets/images/btn/btn_help.png') no-repeat 0 10px;
				@include font(14px);
				font-weight: $fw-regular;
				color: #7D7D7D!important;
				text-transform: none;
				letter-spacing: 0!important;
				&::before{
					opacity: 0;
				}
			}
			.btn-email{
				padding: 0 15px;
				background: url('~@/assets/images/btn/btn_forget_email.png') no-repeat -1px 7px;
				@include font(14px);
				font-weight: $fw-regular;
				color: #7D7D7D!important;
				text-transform: none;
				letter-spacing: 0!important;
				&::before{
					opacity: 0;
				}
			}
			.btn-password{
				padding: 0 15px;
				background: url('~@/assets/images/btn/btn_forget_password.png') no-repeat -1px 7px;
				@include font(14px);
				font-weight: $fw-regular;
				color: #7D7D7D!important;
				text-transform: none;
				letter-spacing: 0!important;
				&::before{
					opacity: 0;
				}
			}
		}
	}
}

// mypage
.mypage{
	.sub-title-1{
		@include font(16px);
	}
	.con{
		.v-btn{
			min-width: 120px;
		}
	}
	.btn-area{
		margin-top: 30px;
		.v-btn{
			min-width: 80px;
			height: 40px!important;
			@include font(14px);
		}
	}
}

// error
.error-area{
	position: absolute;
	left: 50%;
	top: 50%;
	min-width: 500px;
	transform: translate(-50%,-50%);
	text-align: center; 
	.icon-error{
		margin: 0 auto;
	}
	.tit{
		padding: 20px 0 15px;
		@include font(22px, $fc-k);
		font-weight: $fw-regular;
		text-align: center;
	}
	.con{
		padding-bottom: 25px;
		@include font(14px, $fc-k);
		font-weight: $fw-regular;
		text-align: center;
	}
	.v-btn{
		min-width: 80px !important;
		height: 40px !important;
		font-weight: $fw-bold !important;
	}
}

// patner Apps
.pt-tabs{
	.table{
		td{
			.v-btn{
				min-width: 70px !important;
			}
		}
	}
	.form-list{
		li{
			display: flex;
			align-items: flex-start;
			.tit{
				@include font(14px , $fc-k );
				font-weight: $fw-bold;
			}
			.con{
				.txt-area{
					width: 100%;
					background: $background;
					.v-textarea{
						.v-input__slot{
							padding: 0 15px;
							background: $background !important;
							&::before{
								border: none !important;
							}
							&::after{
								content: none;
							}
							.v-text-field__slot{
								textarea{
									height: 300px !important;
									margin: 10px 20px 10px 10px !important;
									overflow-y: scroll !important;
								}	
							}
						}
					}
				}
				.form-group{
					margin-left: -10px;
					.v-btn{
						min-width: 62px;
						height: 32px;
						border-radius: 4px !important;
						font-weight: 500;
						@include font(12px);
					}
				}
			}
		}
		
	}
}

.app-info{
	padding: 14px 0 30px 0;
	.sub-title-1{
		width: 100%;
		@include font(16px);
		height: 1.8rem;
	}
	.con-area{
		width: 100%;
		.con-set1{
			display: flex;
			align-items: flex-start;
			.about{
				width: calc(100% - 185px);
				margin-left: 20px;
				.title{
					display: flex;
					align-items: center;
					padding: 5px 0;
					@include font(26px !important);
					.system{
						padding-left: 15px;
						font-weight: $fw-bold;
						@include font(17px !important);
						&::before{
							content: '|';
							display: inline-flex;
							margin-right: 10px;
							@include font(18px);
						}
					}
					.status{
						padding-left: 20px;
						@include font(14px !important);

						&.fc-bk::before{
							background: #333333;
						}
						&.fc-gr::before{
							background: #00AF46;
						}
						&.fc-g::before{
							background: #A1A1A1;
						}
						&::before{
							display: inline-flex;
							margin-right: 5px;
							content: '';
							width: 10px;
							height: 10px;
							border-radius: 10px;
						}
						
					}
					
				}
				.info-box{
					width: 100%;
					border-top: 1px solid $section;
					border-bottom: 1px solid $section;
					.form-list{
						display: flex;
						flex-wrap: wrap;
						padding: 10px 0;
						li{
							line-height: 1;
							.tit{
								@include font(14px , $fc-k );
								font-weight: $fw-bold;
							}
							.con{
								@include font(14px , $fc-k );
								.url{
									position: relative;
									display: block;
									color: $fc-k;
								}
								@media all and (max-width:1380px){
									@include font(13px);
								}
							}
							&:nth-child(2n){
								width: 60%;
								@media all and (max-width:1680px){
									width: 65%;
								}
								@media all and (max-width:1480px){
									width: 67%;
								}
							}
							&:nth-child(odd){
								width: 40%;
								@media all and (max-width:1680px){
									width: 35%;
								}
								@media all and (max-width:1480px){
									width: 33%;
								}
							}
						}
					}
				}
			
			}
		}
		.con-set2{
			display: flex;
			align-items: center;
			margin-top: 20px;
			.guide-set{
				margin-left: 30px;
				.sub-title-2{
					margin: 0 0 5px;
					font-weight: $fw-bold;
					color: $fc-k;

				}
				.v-btn{
					min-width: 120px !important;
					min-height: 38px !important;
					margin: 15px 0 0 2px !important;
					font-weight: $fw-regular;
					@include font(14px !important);
					&:last-child{
						margin-left: 20px !important;
					}
				}
				
			}
			.warn-box{
				.title{
					@include font(17px !important);
				}
				.warning-list{
					margin: 5px 0 0 20px !important;
					li{
						margin-left: -15px;
						@include font(12px);
						&::before{
							display: inline-flex;
							margin-right: 5px;
							content: '';
							width: 3px;
							height: 3px;
							border-radius: 3px;
							background: $fc-k;
						}
					}
				}
			}
				
		}
	}
}

// chart
.chart-view{
	.chart-area{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		width: 100%;
		margin-top: 10px;
	}
	.chart-info{
		margin-top: 1rem;
		display: flex;
		align-items: center;
		.sub-title-1{
			padding-right: 30px;
			margin-bottom: 0;
		}
		.mark{
			display: flex;
			align-items: flex-start;
			&.ios{
				margin-left: 60px;
			}
			.name{
				margin-left: 5px;
				@include font(14px, $fc-k);
				font-weight: $fw-regular;
			}
		}
		
	}
	.report{
		@include font(14px, $secondary);
		cursor: pointer;
	}
	.btn-setup{
		margin-left: 10px;
	}
}

//invoice
.title-top{
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 40px;
}

//finance
.finance{
	.payment{
		.btn-box{
			display: inline-block;
			width: 100%;
			text-align: right;
		}
	}
}