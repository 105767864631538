// popup
.v-dialog {
	&.scroll-none {
		overflow: hidden !important;
	}

	&--fullscreen {
		.popup {
			&-content {
				width: 80%;
				margin: 25px auto 20px;
			}
		}
	}

	&--scrollable {
		height: 560px;
		background: #fff;

		.popup {
			width: 100%;

			&-content {
				overflow-y: auto;
				backface-visibility: hidden;
				width: 100%;
				height: 100%;
				margin: 25px auto 20px;
				padding: 0 24px;
			}

			.v-card {
				&__title {
					padding: 30px 24px 0 !important;
					@include font(20px !important);
					font-weight: $fw-bold !important;
				}
			}

			&-btns {
				margin-top: 0;
				padding: 30px !important;
			}
		}
	}
	.spinner {
		display:flex;
		justify-content: center;
		align-items:center;
	}
}

// default
.popup {
	background: #fff !important;
	border-radius: 0 !important;

	&.v-card {
		box-shadow: none !important;
	}

	.v-card__title {
		padding: 24px !important;
		line-height: 1;
	}

	.v-toolbar {
		height: 60px !important;
		box-shadow: none !important;

		&__title {
			@include font(20px, #fff);
			font-weight: $fw-bold;
		}

		&__content {
			display: flex;
			align-items: center;
			justify-content: space-between;
			height: inherit !important;

			.btn-pop-close {
				width: 40px !important;
				height: 40px !important;
				margin: 0 !important;
				background: url('~@/assets/images/btn/popup_close.png') no-repeat 0 0;
				background-size: 100%;
			}
		}
	}

	&-content {
		padding: 0 24px;

		.v-input {
			min-height: 40px !important;

			&__slot {
				min-height: 40px !important;
			}

			.v-label {
				font-size: 12px !important;
			}
		}

		// tab content
		.v-tabs {
			&-items {
				.v-window-item {
					overflow-y: auto !important;
					height: calc(100vh - 300px);

					// non scroll
					&.non-scroll {
						overflow-y: hidden !important;
						height: 100%;
						padding: 5px 0 40px;
					}
				}

				.v-card {
					padding: 0 20px;
				}
			}
		}

		.pop-top {

			// datepicker
			.inp-date {
				max-width: 125px;
				margin-bottom: 10px !important;

				&.v-input {
					min-height: 30px !important;

					.v-input__slot {
						min-height: 30px !important;
					}
				}
			}
		}

		.change-list {
			padding: 25px 0 5px;
			border-top: 2px solid $secondary;
			border-bottom: 2px solid $secondary;

			li {
				display: flex;
				align-items: baseline;

				.tit {
					width: 10%;
					@include font(14px);
					font-weight: 600;

					@media all and (max-width: 2200px) {
						width: 15%;
					}

					@media all and (max-width: 1500px) {
						width: 20%;
					}
				}

				.con {
					width: calc(100% - 200px);
					padding-bottom: 20px;
				}
			}

			&.over {
				border: none;
			}
		}

		// toggle list
		.toggle-list {
			border-bottom: 1px solid $fc-g3;

			li {
				display: flex;
				align-items: center;
				justify-content: space-between;
				border-top: 1px solid $fc-g3;

				.tit {
					@include font(14px, $fc-k);
					font-weight: $fw-regular;
				}
			}
		}

		.popup-inner {
			padding: 25px 0;

			.sub-tit {
				.tit {
					@include font(16px, #000);
					font-weight: $fw-bold;

				}

				.ex {
					@include font(12px, #000);
				}
			}
		}
	}

	&-btns {
		display: flex;
		justify-content: center;
		position: relative;
		width: 100%;
		padding: 30px 24px 24px;

		*+* {
			margin-left: 5px;
		}

		.v-btn {
			min-width: 80px !important;
			text-transform: none;
		}

		.btn-groups {
			&.justify-start {
				position: absolute;
				left: 0;
			}
		}
	}

	&.status {
		background: #fff !important;

		.v-toolbar {
			background: #fff !important;

			&__title {
				@include font(20px, $fc-k);
			}
		}

		.popup-content {
			width: 100%;
			padding: 0 20px 30px !important;
			margin: 0 !important;

			.date-info {
				display: flex;
				align-items: center;
				margin-bottom: 20px;
				@include font(13px, $fc-k);

				strong {
					margin-right: 5px;
					@include font(14px, $fc-k);
					font-weight: $fw-bold;
				}
			}
		}
	}

	@media all and (max-width: 1280px) {
		&-content {
			width: 750px;

			// tab content
			.v-tabx {
				&-items {
					overflow-y: auto !important;
					height: 300px;
				}
			}
		}
	}
}

//user-pop
.user-pop {
	&.v-menu__content {
		position: fixed;
		top: calc(100% - 116px) !important;
		left: 80px !important;
		width: min-content;
	}

	box-shadow: none !important;

	.v-list-item {
		padding: 0 !important;

		&:hover {
			background: none !important;
		}
	}

	.theme--light.v-card {
		background: $background !important;
		padding: 20px 28px;

		.v-list-item__content {
			padding: 0 !important;
			margin-bottom: 16px;
		}

		.v-list-item__title {
			@include font(18px !important, #000 !important);
			font-weight: $fw-bold !important;
			margin-bottom: 6px;
		}

		.v-list-item__subtitle {
			@include font(12px !important, #000 !important);
		}
	}

	.menuable__content__active {
		background: $background !important;
		@include font(12px);
	}

	.v-list {
		overflow: hidden;

		.btn-myinfo {
			float: left;
			position: relative;
			@include font(12px !important, #000 !important);
			text-decoration: underline;
			margin-right: 25px !important;

			& .icon-myinfo {
				background: url('~@/assets/images/icon/ico_lock.png') no-repeat 0 0;
				width: 16px;
				height: 14px;
				margin: 2px 2px 0 0;
				background-size: contain;
			}
		}

		.btn-logout {
			position: relative;
			margin-left: 27px;
			width: fit-content;
			@include font(12px !important, #000 !important);
			text-decoration: underline;

			& .icon-logout {
				background: url('~@/assets/images/icon/ico_logout.png') no-repeat 0 0;
				width: 16px;
				height: 14px;
				margin: 2px 2px 0 0;
				background-size: contain;
			}
		}
	}
}

//alert
.alert {
	border-radius: 20px;

	.alert-pop {
		&.v-card {
			padding: 0;

			.v-card__text {
				padding: 100px 50px 30px 50px;
				font-weight: $fw-bold;
				@include font (16px, #000);
				text-align: center;
				word-break: keep-all;
			}

			.v-card__actions {
				justify-content: center;
				border-top: 1px solid $fc-g4;

				.v-btn {
					width: 100%;
					height: 55px;
					background: transparent !important;
					@include font (12px, $primary);

					&:hover {
						&::before {
							opacity: 0 !important;
						}
					}
				}
			}
		}
	}
}

//snackbar
.snackbar {
	position: sticky;
	top: 0;
	
	bottom: inherit !important;
	width: 100% !important;
	z-index:200 !important;

	.btn-pop-close {
		width: 30px !important;
		height: 30px !important;
		margin: 0 !important;
		background: url('~@/assets/images/btn/popup_close.png') no-repeat 0 0;
		background-size: 100%;
	}

	&.v-snack:not(.v-snack--centered):not(.v-snack--top) {
		align-items: flex-start;
	}

	.v-snack__wrapper {
		width: calc(100% - 80px) !important;
		min-width: inherit !important;
		max-width: inherit !important;
		margin: 0 !important;

		.v-snack__content {
			@include font (18px !important, #fff !important);
			text-align: center !important;

			.snackbar-link {
				color: #fff !important;
				text-decoration: underline;
			}
		}
	}
}

// 환율정보(USD) 및 대금지급일 등록
.pop-reg {
	.v-card__title {
		font-weight: $fw-bold !important;
		@include font(20px, #000);
	}

	.popup-content {
		.box-top {
			display: flex;
			align-items: center;

			.sub-title-2 {
				width: 120px;
				font-weight: $fw-bold;
				@include font(14px, #000);
				margin: 0 0 0 20px !important;

				@media all and (max-width:1980px) {
					margin-left: 10px !important;
				}
			}

			.con {
				@include font(12px, $secondary);
			}
		}

		.pop-box {
			width: 100%;
			padding: 15px 0;

			.form-list {
				display: flex;
				flex-wrap: wrap;
				padding: 10px 0;

				li {
					.tit {
						width: 120px;
					}

					.con {
						width: calc(100% - 120px);

						.v-input {
							min-width: 200px;
						}

						.inp-date {
							min-height: 42px !important;

							.v-input__icon {
								.v-icon {
									&::before {
										margin-top: 4px;
									}
								}
							}
						}
					}

					&:nth-child(odd) {
						width: calc(50% - 50px);
					}

					&:nth-child(2n) {
						width: calc(50% - 50px);
						margin-left: 100px;
					}
				}

			}
		}
	}

	.popup-btns {
		.v-btn {
			min-width: 80px;
			height: 40px;
			border-radius: 2px;
			@include font(14px);
		}
	}
}

.pop-more {
	.v-card__title {
		padding-bottom: 30px !important;
		font-weight: $fw-bold !important;
		@include font(20px, #000);
	}

	.popup-content {
		.total {
			display: flex;
			align-items: center;
			width: 100%;
			margin-bottom: 20px;

			.tit {
				width: 105px;
				@include font(16px, #000);
				font-weight: $fw-bold;
			}

			.price {
				@include font(12px, $fc-g1);

				strong {
					margin-right: 20px;
					@include font(16px, #000);
					font-weight: $fw-bold;
				}
			}
		}

		.pop-top {
			margin-top: 6px;
			padding-top: 16px;
			border-top: 1px solid #000;

			&:first-child {
				padding-top: 0;
				border-top: none;
			}

			.sub-title-2 {
				width: 120px;
				margin: 0;
				font-weight: $fw-bold;
				@include font(16px, #000);

			}
		}

		.pop-box {
			width: 100%;

			.form-list {
				display: flex;
				flex-wrap: wrap;
				width: 100%;
				padding: 10px 0;

				li {
					width: calc(50% - 10px);
					padding: 5px 0;

					.tit {
						width: 50%;
						padding-right: 10px;
						@include font(12px, $secondary);
					}

					.con {
						width: 50%;
						@include font(12px, #000);

						.v-input {
							min-width: 200px;
						}

						.inp-date {
							min-height: 42px !important;

							.v-input__icon {
								.v-icon {
									&::before {
										margin-top: 4px;
									}
								}
							}
						}
					}

					&:nth-child(2n) {
						margin-left: 20px;
					}
				}
			}

		}

		@media all and (max-width: 1280px) {
			width: auto;
		}
	}

}

.pop-info {
	.sub-title-2 {
		margin: 15px 0;
		font-weight: $fw-bold;
		@include font(14px, #000);

	}

	.box-top {
		.form-list {
			border-bottom: 1px solid #e4e4e4;
		}
	}

	.table {
		table {
			td {
				word-break: keep-all;
			}
		}
	}
}

.warning-mimic-login{
	border-radius: 0 !important;
	margin: 0;
	width: calc(100% - 80px);
	position: fixed;
	bottom: 0;
	right: 0;
}